import React from "react";

class PageLoading extends React.Component {
    render() {
        const { blocking, title } = this.props;
        if ( blocking ) {
            return (
                <div className="page-loading blocking text-left">
                    <span>{title} &nbsp; <i className="fa fa-spin fa-spinner" /></span>
                </div>
            )
        }

        return (
            <div className="page-loading text-left" style={{minHeight: '300px'}}> Loading &nbsp; <i className="fa fa-spin fa-spinner" /> </div>
        )
    }
}


PageLoading.defaultProps = {
    title: 'Loading'
}

export default PageLoading;
