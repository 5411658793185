import React from "react";
import lodash from 'lodash';

import ApiClient from './../../common/lib/ApiClient';
import Button from "react-bootstrap/Button";
import moment from "moment-timezone";
import UserStore from "../../common/stores/User";

class ManageLicense extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            licenses: null,
            isLoading: false,
        }
    }

    handleRevokeLicense = (licenseId) => (event) => {
        this.setState({isLoading: true});

        ApiClient.get({
            endpoint: `/api/v1/licensing/${licenseId}/revoke`
        }).then(res => {

            this.props.onClose && this.props.onClose();
            setTimeout(() =>
                this.props.reloadData && this.props.reloadData(), 200)

        }, err => {
            ApiClient.onError(err)
        })
    };

    handleAssignLicense = (licenseId) => {
        this.setState({isLoading: true});

        ApiClient.post({
            endpoint: `/api/v1/licensing/${licenseId}/assign`,
            data: {
                subscription_id: this.props.subscription.id,
            }
        }).then(res => {

            this.props.onClose && this.props.onClose();
            setTimeout(() =>
                this.props.reloadData && this.props.reloadData(), 200)

        }, err => {
            ApiClient.onError(err)
        })
    };

    componentDidMount() {
        ApiClient.get({
            endpoint: '/api/v1/licensing/available'

        }).then(res => {
            this.setState({licenses: res.body.data});
        }, (err) => {

        });

    }

    render() {
        const {
            subscription,
        } = this.props;

        const {
            isLoading,
            licenses,
        } = this.state;

        let {timezone} = UserStore.getUser();
        timezone = timezone ? timezone : 'UTC';

        // License details
        let licenseInfo = null;
        if (subscription.license && subscription.license.data && subscription.license.data.id) {
            licenseInfo =  subscription.license.data;
        }

        if (licenseInfo) {
            const handleRevokeLicense = this.handleRevokeLicense(licenseInfo.id)
            const revokeAllowed = licenseInfo.usage_count <= licenseInfo.allowed_revokes;

            return (
                <>
                    <div className="text-center ">
                        <p className="size16 ">
                            The account <strong>{subscription.display_name}</strong> is <strong className="text-success">licensed</strong>
                            <br/>and the license will expire
                            &nbsp;<strong>{moment.unix( subscription.subscription_end ).tz(timezone).format('MMM D, YYYY')}</strong>
                        </p>

                    </div>
                    {!revokeAllowed ?
                        <p className="text-center text-danger">The license can not be revoked, the additional revokes limit has been reached.</p>
                        :
                        <>
                            <p className="text-center ">If you revoke the license, the remote account will stop working.</p>
                            <div className="text-center marginT">
                                <Button variant="danger" onClick={!isLoading ? handleRevokeLicense : null} disabled={isLoading}>
                                    {isLoading ? 'Loading…' : 'Revoke license'}
                                </Button>
                            </div>
                        </>
                    }
                </>
            )
        }

        return (
            <div>
                <div className="text-center ">
                    <p className="size16 text-danger">The account <strong>{subscription.display_name}</strong> is <strong>not licensed</strong></p>
                    <p>You can assign a license to this account by choosing one from the available poll.</p>

                </div>

                <div className="marginT">

                    <h5 className="text-center">Available licenses</h5>

                    <table className="table table-allowed-listeners">
                        <thead>
                        <tr>
                            <th scope="col">Duration</th>
                            <th scope="col" className="text-center">Remaining revokes</th>
                            <th scope="col">&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!licenses || !licenses.length ?
                            <tr>
                                <td colSpan={3}>No licenses available</td>
                            </tr>
                            :
                            licenses.map((license) => {
                                return (
                                    <tr key={`all-${license.id}`}>
                                        <th scope="row">{license.remaining_duration} days</th>
                                        <td align="center">{license.allowed_revokes - license.usage_count}</td>
                                        <td align="right">
                                            <a href="javascript:;" onClick={() => this.handleAssignLicense(license.id)} className="text-danger">Assign license</a>
                                        </td>
                                    </tr>)
                            })
                        }

                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default ManageLicense
