import React from 'react';
import _ from 'lodash';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ApiClient from './../../common/lib/ApiClient';

class Attribute extends React.PureComponent {
    render() {
        const {data,value} = this.props;

        let element = null;
        if ( 'select' == data.field_type ) {
            let options = [];
            if ( data.field_options ) {
                options = JSON.parse ( data.field_options );
            }
            element = (
                <select className="form-control"
                        name={data.field_name}
                        value={value ? value : ''}
                        required={Boolean( data.is_required )}
                        onChange={this.props.onChange}>
                    <option>- choose -</option>
                    {options.map(function(d, i) {
                        return (<option key={'asf' + i} value={d}>{d}</option> );
                    })}
                </select>
            )

        }
        if ( 'int' == data.field_type ) {
            element = (<input className="form-control"
                   type="number"
                   name={data.field_name}
                   value={value ? value : ''}
                  required={Boolean( data.is_required )}
                   onChange={this.props.onChange} />)
        }

        if ( !element ) { // Render as text
            element = (<input className="form-control"
                              type="text"
                              name={data.field_name}
                              value={value ? value : ''}
                              required={Boolean( data.is_required )}
                              onChange={this.props.onChange} />)
        }
        return (
            <div className="extra-attributes">
                <Form.Group as={Row}>
                    <Form.Label column lg="4">{data.label} {data.is_required?'*':''}</Form.Label>
                    <Col lg="8 ">
                        {element}
                    </Col>
                </Form.Group>
            </div>
        )
    }

}

class Attributes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            attributes: null
        }
    }

    componentDidMount() {
        ApiClient.get({
            endpoint: '/api/v1/system/eav-attributes',
            query: {
                entity_type: 'subscription'
            }

        }).then(res => {
            this.setState({
                dataChanged: false,
                attributes: res.body.data ? res.body.data : []
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    render() {
        const {attributes}      = this.state;
        const {subscription}    = this.props;

        if ( attributes == null ) {
            return (<span>Loading</span>)
        }

        if ( attributes && !attributes.length ) {
            return (<span>No extra attributes defined</span>)
        }

        return (
            <div>
                {attributes.map(function(d,i) {
                    // Determine attribute
                    const attribute = subscription.attributes ? _.find(subscription.attributes.data, { 'field_name': d.field_name }) : null;
                    const value = attribute ? attribute.value : undefined;

                    return ( <Attribute key={'sav' + i} data={d} value={value} onChange={this.props.onChange} /> );
                }.bind(this))}
            </div>
        )
    }
}


export default Attributes;