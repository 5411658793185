import ApiClient from './../../common/lib/ApiClient';

var EventEmitter = require('events').EventEmitter;
var emitter = new EventEmitter();

var _subscriptions = [];

export default {
    getSubscriptions: function() {
        return _subscriptions.concat();
    },

    subscribe: function(callback) {
        emitter.on('update', callback);
    },

    unsubscribe: function(callback) {
        emitter.removeListener('update', callback);
    },

    setData: function ( subscriptions ) {
        _subscriptions = subscriptions;
        emitter.emit('update');
    },

    loadFromServer: function () {
        ApiClient.get({
            endpoint: '/api/v1/subscription'

        }).then(res => {
            _subscriptions = res.body.data;
            emitter.emit('update');

        }, (err) => {

        })

    },

    addListener( remote_id, listener_id, callback ) {


        ApiClient.post({
            endpoint: '/api/v1/subscription/' + remote_id + '/listener',
            data: {
                listener_id
            }
        }).then((res) => {
            if (callback) callback(res)

        }, (err) => {
            ApiClient.onError(err)

        });
    },

    removeListener( remote_id, listener_id, callback ) {


        ApiClient.delete({
            endpoint: '/api/v1/subscription/' + remote_id + '/listener/' + listener_id
        }).then((res) => {
            if (callback) callback(res)

        }, (err) => {
            ApiClient.onError(err)

        });
    },
};
