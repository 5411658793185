import React,{Component} from "react";
import { Link } from 'react-router-dom';

import ApiClient from './../../common/lib/ApiClient';
import UsersStore from './../../common/stores/Users';
import UserStore from './../../common/stores/User';
import SettingsStore from './../../common/stores/Settings';

import _ from 'lodash';
import bootbox from 'bootbox';
import moment from 'moment-timezone'

import ReactTable from 'react-table';
import CustomPaginationComponent from './../../common/lib/ReactTable-Pagination';

import classnames from "classnames";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Badge from "react-bootstrap/Badge";

export default class UsersPage extends React.Component {

    constructor(props) {
        super(props)

        this.getUsers           = this.getUsers.bind(this)
        this._onSearchChange    = this._onSearchChange.bind(this)
        this.handleDelete       = this.handleDelete.bind(this)

        this.state = {
            loaded: false,
            loading: false,
            filters: {},
            query: ''
        };
    }

    getUsers() {
        this.setState({loading: true});

        ApiClient.get({
            endpoint: '/api/v1/user',
            query: {
                include: 'subscriptions',
                q: this.state.query
            }

        }).then(res => {

            this._dataList = res.body.data;

            this.setState({
                data: res.body.data,
                loaded: true,
                loading: false
            }, function() {
                let { data } = this.state;
                UsersStore.setData( data );
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    _onSearchChange(e) {
        if (!e.target.value) {
            this.setState({
                data: this._dataList,
            });
        }

        let filterBy = e.target.value.toLowerCase();

        let newDataList = _.filter(this._dataList, function(i) {
            let {username, first_name, last_name } = i;
            return ( username && username.toLowerCase().indexOf(filterBy) !== -1 )
                || ( first_name && first_name.toLowerCase().indexOf(filterBy) !== -1 )
                ||  ( last_name && last_name.toLowerCase().indexOf(filterBy) !== -1 );
        });

        this.setState({
            data: newDataList,
            q: filterBy
        });
    }

    handleDelete (id) {

        bootbox.confirm({
            title: 'Confirm user removal',
            message: "Are you sure you want to delete this user? The action can not be undone.",
            callback: function(result) {

                if ( result ) {
                    ApiClient.delete({
                        endpoint: '/api/v1/user/' + id

                    }).then(function(res) {
                        this.getUsers()


                    }.bind(this), (err) => {
                        ApiClient.onError(err)
                    })
                }

            }.bind(this),

            buttons: {
                cancel: {
                    label: 'Cancel',
                    className: 'btn-default'
                },
                confirm: {
                    label: 'Delete',
                    className: 'btn-danger'
                }
            }
        });
    }

    componentDidMount() {
        document.title = "Accounts | " + SettingsStore.getConfig().site_name;

        const users = UsersStore.getUsers();
        this.setState({
            data: users
        }, () => this.getUsers());
    }

    render() {

        let {timezone} = UserStore.getUser();
        timezone = timezone ? timezone : 'UTC';

        const columns = [
            {
                Header: 'Username',
                sortable: true,
                accessor: 'username',
                filterable: true,
                filterAll: true,
                filterMethod: (filter, rows) => {
                    return _.filter( rows, (o) => { return -1 != o.username.toLowerCase().indexOf ( filter.value.toLowerCase() ) } );
                },
                Cell: row => {
                    let span = '';

                    if ( 'super-admin' == row.original.user_role ) {
                        span = (<Badge variant="primary" className="inline-block marginL half">Super Admin</Badge> )
                    }

                    // Add last login
                    let login = 'never'
                    if ( row.original.last_login_at ) {
                        let c = moment.unix(row.original.last_login_at).tz(timezone);
                        login = c.format('HH:mm MMM D, YYYY');
                    }

                    return (
                    <div>
                        <span className="text-weight-500">{row.value}</span> {span}
                        <div className="last-login help-block">Last login @ {login}</div>
                    </div>
                )}
            },
            {
                Header: 'Name',
                sortable: true,
                accessor: 'first_name',
                filterable: true,
                filterMethod: (filter, row) => {
                    if ( filter.value === null ) {
                        filter.value = ''
                    }

                    let name = '';
                    if ( row._original ) {
                        name = (row._original.first_name?row._original.first_name:'') + ' ' + (row._original.last_name?row._original.last_name:'')
                    }

                    return -1 !== name.toLowerCase().indexOf( filter.value.toLowerCase() );
                },

                Cell: ({row}) => {
                    let { first_name,last_name } = row._original;
                    return ( <span>{(first_name?first_name:'') + ' ' + (last_name?last_name:'')}</span> ) }
            },
            {
                Header: 'Email',
                sortable: false,
                accessor: 'email_address',
                filterable: true,
                filterAll: true,
                filterMethod: (filter, rows) => {
                    return _.filter( rows, (o) => { return -1 != o.email_address.toLowerCase().indexOf ( filter.value.toLowerCase() ) } );
                },
                Cell: row => (
                    <a href={'mailto:' + row.value}>
                        {row.value}
                    </a>
                )
            },
            {
                Header: 'Phone Number',
                sortable: false,
                accessor: 'phone_number'
            },
            {
                Header: 'Accounts',
                sortable: false,
                accessor: 'subscriptions',
                className: 'text-center',
                headerClassName: 'text-center',
                Cell: ({row, value}) => {
                    return(<div>
                        {value.data.length} / {row._original && row._original.allowed_subscriptions == null ? '-' : row._original.allowed_subscriptions}
                    </div>)
                }
            },

            {
                Header: 'Status',
                sortable: false,
                accessor: 'is_active',
                className: 'text-center',
                headerClassName: 'text-center',
                width: 100,
                filterable: true,
                filterMethod: (filter, row) => {
                    if (filter.value === 'all') {
                        return true
                    }

                    return row.is_active == parseInt(filter.value);
                },
                Filter: ({ filter, onChange }) => {
                    return(<select
                        autoComplete="off"
                        onChange={event => onChange(event.target.value)}
                        style={{ width: '100%' }}
                        value={filter ? filter.value : 'all'}
                    >
                        <option value="all">All</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                    </select>)
                },
                Cell: row => (
                    <span>{row.value ? 'Active' : 'Inactive'}</span>
                )
            },
            {
                Header: '',
                sortable: false,
                accessor: 'id',
                width: 100,
                className: 'col-with-dropdown',
                Cell: row => <RowActions id={row.value} data={row._original} onDelete={this.handleDelete} />
            }];


        let dataList = this.state.data;

        const refreshCx = classnames({
            'fa-spin': this.state.loading,
            'far fa-sync': true
        })

        return (
            <React.Fragment>

                <h1 className="marginB">Accounts</h1>

            {/*
                <div className="clearfix marginB innerB half">
                    <button className="btn btn-default  pull-left marginR "
                            disabled={this.state.loading}
                            onClick={this.getUsers}><i className={refreshCx}/></button>

                    <Link to="/user" className="btn btn-warning  pull-left ">
                        <i className="fal fa-user-plus marginR half" /> Add client
                    </Link>
        </div>*/}

                <div className="content">

                  <div>

                       <ReactTable
                          PaginationComponent={CustomPaginationComponent}
                          showPaginationTop={true}
                          showPageSizeOptions={true}
                          pageSizeOptions={[10, 25, 50, 100, 200]}
                          className=""
                          paginationClassName="pagination-filters clearfix"
                          data={dataList}
                          columns={columns}
                          defaultPageSize={10}
                          minRows={1}
                          loading={!this.state.loaded}
                      />

                  </div>
                </div>
            </React.Fragment>
        );
    }
}

class RowActions extends Component {

    render() {

        let { id } = this.props
        return (
        <div className="rowActions">

            <DropdownButton variant="link" className="text-warning" title="Manage" alignRight>
                <Dropdown.Item  as={Link} to={"/user/" + id}>Edit</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="javascript:;"
                               variant="danger"
                               onClick={(event) => this.props.onDelete( id ) }>Delete</Dropdown.Item>
            </DropdownButton>
        </div> );

    }
}
