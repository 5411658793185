import React,{Component} from "react";
import { Link } from 'react-router-dom';

import ApiClient from './../../common/lib/ApiClient';
import LogsStore from './../../common/stores/Logs';
import UserStore from './../../common/stores/User';
import SettingsStore from './../../common/stores/Settings';

import _ from 'lodash';
import bootbox from 'bootbox';
import classnames from 'classnames'
import moment from 'moment-timezone'

import ReactTable from 'react-table';
import CustomPaginationComponent from './../../common/lib/ReactTable-Pagination';
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";



export default class DebugLogs extends React.Component {

    constructor(props) {
        super(props)

        this.getLogs            = this.getLogs.bind(this)
        this.handleDelete       = this.handleDelete.bind(this)

        this.state = {
            loaded: false,
            filters: {},
            query: ''
        };
    }

    getLogs() {

        ApiClient.get({
            endpoint: '/api/v1/debuglog'

        }).then(res => {

            this._dataList = res.body.data;

            this.setState({
                data: res.body.data,
                loaded: true
            }, function() {
                let { data } = this.state;
                LogsStore.setData( data );
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    handleDelete (id) {

        bootbox.confirm({
            title: 'Confirm debug log removal',
            message: "Are you sure you want to delete this debug log? The action can not be undone.",
            callback: function(result) {

                if ( result ) {
                    ApiClient.delete({
                        endpoint: '/api/v1/debuglog/' + id

                    }).then(function(res) {
                        this.getLogs()


                    }.bind(this), (err) => {
                        ApiClient.onError(err)
                    })
                }

            }.bind(this),

            buttons: {
                cancel: {
                    label: 'Cancel',
                    className: 'btn-default'
                },
                confirm: {
                    label: 'Delete',
                    className: 'btn-danger'
                }
            }
        });
    }

    componentDidMount() {
        document.title = "Debug logs | " + SettingsStore.getConfig().site_name;

        const Logs = LogsStore.getLogs();
        this.setState({
            data: Logs
        }, () => this.getLogs());
    }

    render() {

        const usernames = _.uniqBy(this._dataList, 'sip_username')
        /*const packages = _.uniqBy(this._dataList, 'package_name')*/

        let {timezone} = UserStore.getUser();
        timezone = timezone ? timezone : 'UTC';

        const columns = [

            {
                Header: 'Chat Username',
                accessor: 'sip_username',
                sortable: true,
                filterable: true,
                filterMethod: (filter, row) => {
                    if (filter.value === 'all') {
                        return true
                    }

                    if (filter.value === row.sip_username) {
                        return true
                    }

                    return false
                },
                Filter: ({ filter, onChange }) => {
                    return(<select
                        autoComplete="off"
                        onChange={event => onChange(event.target.value)}
                        style={{ width: '100%' }}
                        value={filter ? filter.value : 'all'}
                    >
                        <option value="all">- all -</option>
                        {usernames.map(function(d) {
                            return (<option key={'su' + d.sip_username} value={d.sip_username}>{d.sip_username}</option>)
                        })}
                    </select>)
                },

                Cell: ({row}) => {
                    return ( <span>{row._original.sip_username ? row._original.sip_username : '-'}</span> )
                }
            },



            /*{
                Header: 'Package',
                accessor: 'package_name',
                sortable: true,
                filterable: true,
                filterMethod: (filter, row) => {
                    if (filter.value === 'all') {
                        return true
                    }

                    if (filter.value === row.package_name) {
                        return true
                    }

                    return false
                },
                Filter: ({ filter, onChange }) => {
                    return(<select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: '100%' }}
                        value={filter ? filter.value : 'all'}
                    >
                        <option value="all">- all -</option>
                        {packages.map(function(d) {
                            return (<option key={'sp' + d.package_name} value={d.package_name}>{d.package_name}</option>)
                        })}
                    </select>)
                },

                Cell: ({value}) => {
                    return ( <span>{value ? value : '-'}</span> )
                }
            },*/


            {
                Header: 'Version',
                className: 'text-center',
                headerClassName: 'text-center',
                sortable: true,

                accessor: 'app_version_code',

                Cell: ({original}) => {
                    return ( <div>{original.app_version}</div> )
                }
            },

            {
                Header: 'File name',
                sortable: true,

                accessor: 'file_name',

                filterable: true,
                filterAll: true,
                filterMethod: (filter, rows) => {
                    return _.filter( rows, (o) => { return -1 != o.sip_username.toLowerCase().indexOf ( filter.value.toLowerCase() ) } );
                },

                Cell: ({row, original}) => {
                    return ( <div>{original.file_name}</div> )
                }
            },

            {
                Header: 'File size',
                sortable: true,

                accessor: 'file_size',

                filterable: false,

                Cell: ({row, original}) => {
                    let bytes = original.file_size;

                    if(bytes == 0) return '0 Bytes';
                    let k = 1024,
                        dm = 2,
                        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                        i = Math.floor(Math.log(bytes) / Math.log(k));

                    const filesize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];

                    return ( <div>{filesize}</div> )
                }
            },

            {
                Header: 'Uploaded',
                sortable: true,
                accessor: 'upload_time',
                className: 'text-center',
                headerClassName: 'text-center',
                width: 140,
                Cell: row => {
                    if ( !row.value ) {
                        return null;
                    }

                    var c = moment.unix(row.value).tz(timezone);
                    return ( <span>{c.format('HH:mm MMM D, YYYY')}</span> )
                }
            },
            {
                Header: '',
                sortable: false,
                accessor: 'id',
                className: 'text-center',
                headerClassName: 'text-center',
                width: 120,
                Cell: ({original}) => {
                    return (
                        <a href={"/api/v1/debuglog/" + original.local_file_name + "/download"}
                           className="btn btn-light"
                            target="_blank">Download</a>
                    )
                }
            },
            {
                Header: '',
                sortable: false,
                accessor: 'id',
                width: 120,
                className: 'text-right col-with-dropdown',
                Cell: row => <RowActions id={row.value} is_active={row.original.is_active} data={row.original}
                                         do_not_list={row.original.do_not_list}
                                         onDoNotListChange={this.handleDoNotListChange}
                                         onStatusChange={this.handleStatusChange}
                                         onDelete={this.handleDelete} />
            }];


        let dataList = this.state.data;

        return (
            <React.Fragment>

                <h1>Debug Logs</h1>
          <div className="content">
            <p className="welcomeText"></p>

              <div>
                   <ReactTable
                      PaginationComponent={CustomPaginationComponent}
                      showPaginationTop={true}
                      showPageSizeOptions={true}
                      pageSizeOptions={[10, 25, 50, 100, 200]}
                      className=""
                      paginationClassName="pagination-filters clearfix"
                      data={dataList}
                      columns={columns}
                      defaultPageSize={10}
                      minRows={1}
                      loading={!this.state.loaded}
                  />

              </div>
          </div>
            </React.Fragment>
        );
    }
}

class RowActions extends Component {

    render() {

        let { id  } = this.props

        return (
        <div className="row-actions actions-short">

            <DropdownButton variant="link" className="text-warning" title="Manage" alignRight>
                <Dropdown.Item href="javascript:;"
                               variant="danger"
                               onClick={(event) => this.props.onDelete( id ) }>Delete</Dropdown.Item>
            </DropdownButton>
        </div> );

    }
}
