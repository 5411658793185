import ApiClient from './../../common/lib/ApiClient';

var EventEmitter = require('events').EventEmitter;
var emitter = new EventEmitter();

var _listeners = [];

export default {
    getListeners: function() {
        return _listeners.concat();
    },

    subscribe: function(callback) {
        emitter.on('update', callback);
    },

    unsubscribe: function(callback) {
        emitter.removeListener('update', callback);
    },

    setData: function ( listeners ) {
        _listeners = listeners;
        emitter.emit('update');
    },

    loadFromServer: function () {
        ApiClient.get({
            endpoint: '/api/v1/listener'

        }).then(res => {
            _listeners = res.body.data;
            emitter.emit('update');

        }, (err) => {

        })

    },
};
