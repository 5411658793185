import React from 'react';


import Select from 'react-select'

import Messages from './messages'; // added
import FileTransfers from './files'; // added
import Calls from './calls';
import UsersOnline from './users-online';

import StatsDateRangePicker from './daterangepicker';
import ApiClient from "../../common/lib/ApiClient";

import Card from "react-bootstrap/Card"

class Statistics extends React.Component {
    state = {
        timerangeFilter: null,
        regions: null
    }

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.fetchFilters()
        this.fetchTotals()
    }

    fetchFilters() {
        this.setState({loading: true});

        ApiClient.get({
            endpoint: '/api/v1/analytics/filters'

        }).then(res => {
            // Data
            let filters = res.body.data && res.body.data.filters ? res.body.data.filters : null;

            this.setState({
                data: res.body.data,
                regions: filters && filters.region ? filters.region : null,
                users: filters && filters.users ? filters.users : null,
                loaded: true,
                loading: false
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    fetchTotals() {
        this.setState({loadingTotals: true});

        ApiClient.get({
            endpoint: '/api/v1/analytics/statistics/totals',
            query: {
                timerange: this.state.timerangeFilter,
                filters: {
                    region: this.state.regionFilter,
                    username: this.state.accountFilter
                }
            }

        }).then(res => {
            // Data
            let totals = res.body.data && res.body.data.totals ? res.body.data.totals : null;

            this.setState({
                totals: totals,
                loadingTotals: false
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    handleFilterTimerangeChange(startDate,endDate) {


        let newts = ( startDate.unix() ).toString() + ':';
        if ( endDate ) {
            newts = newts + ( endDate.unix() ).toString();
        }

        this.setState({
            timerangeFilter: newts
        }, () => {
            this.fetchTotals()
        })
    }

    render() {
        const {regions, users, regionFilter, accountFilter} = this.state;

        let selectRegions = [];
        if ( regions ) {
            for ( let i in regions ) {
                let regionName = regions[i] ? regions[i] : 'Unknown'

                selectRegions.push({
                    value: regions[i],
                    label: regionName
                })
            }
        }

        return (
            <div>
                <h1>Statistics Dashboard</h1>

                <div className={'marginB bg-white content'}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <h4 className="margin-top-none">Filters</h4>
                        </div>
                        <div className={'col-md-12'}>
                            <div className="pull-left" style={{width: '400px'}}>
                                <StatsDateRangePicker onChange={this.handleFilterTimerangeChange.bind(this)} />
                            </div>

                            <div className="pull-left marginL" style={{width: '300px'}}>
                                <Select name={'region'}
                                        placeholder={'Filter by region'}
                                        options={selectRegions}
                                        className="stats-filter"
                                        classNamePrefix="select"
                                        isClearable
                                        isSearchable
                                        defaultValue={regionFilter}
                                        onChange={(ev) => {
                                            if ( ev && ev.value ) {
                                                this.setState({
                                                    regionFilter: ev.value
                                                }, () => {
                                                    this.fetchTotals()
                                                })
                                            } else {
                                                this.setState({
                                                    regionFilter: null
                                                }, () => {
                                                    this.fetchTotals()
                                                })
                                            }
                                        }}
                                        />
                            </div>

                            <div className="pull-left marginL" style={{width: '300px'}}>
                                <Select name={'username'}
                                        placeholder={'Filter by username'}
                                        options={users}
                                        className="stats-filter"
                                        classNamePrefix="select"
                                        isClearable
                                        isSearchable
                                        defaultValue={accountFilter}
                                        onChange={(ev) => {
                                            if ( ev && ev.value ) {
                                                this.setState({
                                                    accountFilter: ev.value
                                                }, () => {
                                                    this.fetchTotals()
                                                })
                                            } else {
                                                this.setState({
                                                    accountFilter: null
                                                }, () => {
                                                    this.fetchTotals()
                                                })
                                            }
                                        }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="totals-by-region">
                    <Card className="marginB">
                        <Card.Body>
                            <Card.Title>Totals by region</Card.Title>
                            <div>
                                {this.state.loadingTotals || !this.state.totals || !this.state.totals.region ?
                                <div className="innerAll half text-center marginAll">Loading
                                    &nbsp;
                                    <i className={'fa fa-spin fa-spinner fa-1x '}/>
                                </div>
                                :
                                 <div>
                                     <table className="table">
                                         <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                {/*<th className={'text-center'}>Active Users</th>*/}
                                                <th className={'text-center'}>Sent Messages</th>
                                                <th className={'text-center'}>Received Messages</th>
                                                <th className={'text-center'}>Sent Files</th>
                                                <th className={'text-center'}>Received Files</th>
                                                <th className={'text-center'}>Outgoing Calls</th>
                                                <th className={'text-center'}>Incoming Calls</th>
                                            </tr>
                                         </thead>
                                         <tbody>
                                         {this.state.totals.region.map(function(d,i) {
                                             return (
                                                 <tr key={'sttt' + i}>
                                                     <th>{d.region}</th>
                                                     {/*<td className={'text-center'}>{d.active_users}</td>*/}
                                                     <td className={'text-center'}>{d.message_sent}</td>
                                                     <td className={'text-center'}>{d.message_received}</td>
                                                     <td className={'text-center'}>{d.file_sent}</td>
                                                     <td className={'text-center'}>{d.file_received}</td>
                                                     <td className={'text-center'}>{d.call_outgoing}</td>
                                                     <td className={'text-center'}>{d.call_incoming}</td>
                                                 </tr>
                                             )
                                         })}
                                         </tbody>
                                     </table>
                                 </div>
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                <UsersOnline
                    timerangeFilter={this.state.timerangeFilter}
                    filters={{
                        region: this.state.regionFilter,
                        username: this.state.accountFilter
                    }}
                />

                <Messages
                    timerangeFilter={this.state.timerangeFilter}
                    filters={{
                        region: this.state.regionFilter,
                        username: this.state.accountFilter
                    }}
                />

                <FileTransfers
                    timerangeFilter={this.state.timerangeFilter}
                    filters={{
                        region: this.state.regionFilter,
                        username: this.state.accountFilter
                    }}
                />

                <Calls
                    timerangeFilter={this.state.timerangeFilter}
                    filters={{
                        region: this.state.regionFilter,
                        username: this.state.accountFilter
                    }}
                />
            </div>
        )
    }
}

export default Statistics;