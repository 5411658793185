import React from 'react'
//import { ResponsivePie } from '@nivo/pie'

import { PieChart, Pie, Legend, Cell, Tooltip, ResponsiveContainer, Sector, Label, LabelList } from 'recharts';



import COLORS from './../../common/lib/colors';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {

    // ----
    const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
    const x  = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy  + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{fontSize: '12px'}}>
            {`${value} accounts`}
        </text>
    );
};

export default class PieAppVersion extends React.Component {

    render() {

        const {data} = this.props;
        if ( !data ) {
            return null;
        }

        return(
            <ResponsiveContainer>
            <PieChart
            >
                <Pie
                    label
                    dataKey={'value'}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    data={data}>

                    {
                        data.map((entry, index) => <Cell key={'pavc' + index} fill={COLORS.stats[index % COLORS.stats.length]}/>)
                    }

                </Pie>
                <Legend verticalAlign="top" height={36}/>
            </PieChart>
            </ResponsiveContainer>
        )
    }
}