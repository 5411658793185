import React, {Component} from 'react';
import _ from 'lodash';

import {secondsToDuration} from './../../common/lib/helper';
import classnames from "classnames";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import {Link} from "react-router-dom";

class RecordingRegions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }


    render() {

        let {
            className,
            onPause,
            onPlay,
            regions,
        } = this.props;

        const handlePlay = (regionId) => {
            this.props.onPlay && this.props.onPlay(regionId);
        }

        const handleRemove = (regionId) => {
            this.props.onRemoveRegion && this.props.onRemoveRegion(regionId);
        }

        const handleDownload = (regionId) => {
            this.props.onDownloadRegion && this.props.onDownloadRegion(regionId);
        }

        if (regions && regions.length > 0) {
            regions = _.sortBy(regions, ['start'])
        }

        return (
            <div className={className}>
                {!regions || !regions.length ?
                    null
                    :
                    <>
                        {/*<h5 className="text-weight-300">Regions</h5>*/}
                        <div className="waveform-regions-list">{regions.map((data,idx) => {
                            return (
                                <div className="mb-4 d-flex" style={{alignItems: 'center'}} key={`region-${data.id}`}>
                                    <div className="mr-2">
                                        <span className="recording-duration">{secondsToDuration(data.start)}</span>

                                    </div>

                                    <div className="mr-3"><i className="fal fa-arrow-right"/> </div>

                                    <div className="mr-5">
                                        <span className="recording-duration">{secondsToDuration(data.end)}</span>

                                    </div>


                                    <div>
                                        <button className="btn  btn-sm mr-2"
                                                onClick={handlePlay.bind(this, data.id)}>
                                            <i className="fas fa-play"/>
                                        </button>

                                        <button className="btn  btn-sm mr-3"
                                                onClick={onPause}>
                                            <i className="fas fa-pause"/>
                                        </button>

                                        <button className="btn btn-primary btn-sm mr-5"
                                                title="Download audio region"
                                                onClick={handleDownload.bind(this, data.id)}>
                                            <i className="fal fa-download"/>
                                        </button>

                                        <DropdownButton variant="text-dark"
                                                        className="text-warning d-inline-block" title={<i className="fal fa-cog"/>} alignRight>
                                            <Dropdown.Item href="javascript:;"
                                                           variant="danger"
                                                           onClick={handleRemove.bind(this, data.id)}>
                                                Delete
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                </div>
                            )
                        })}
                        </div>
                    </>
                }
            </div>
        )
    }
}

export default RecordingRegions;