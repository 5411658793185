import React from 'react'
import Sticky from 'react-stickynode'
import {Link} from 'react-router-dom'

class PageHeading extends React.Component {


    render() {
        let {props} = this;

        return (

            <Sticky enabled={true} top={0}
                    activeClass={'active page-heading'}
                    innerZ={2}>
                <div className="clearfix page-heading">
                    <h1 className="pull-left">{props.title}</h1>
                    <div className="pull-left">
                        <div className="formActions form-group margin-bottom-none marginL">
                            <div className="">

                                <Link to={this.props.cancelGoToUrl}>

                                    <button className="btn btn-light inline-block marginR half ">Cancel</button>
                                </Link>

                                <button onClick={props.onSave}
                                        disabled={!props.dataChanged || props.formError}
                                        className="btn btn-warning inline-block">Save</button>
                            </div>
                        </div>
                    </div>

                    <div className="help-block text-right pull-right innerT  marginT half"
                         style={{ visibility: props.dataChanged ? 'visible' : 'hidden', color: '#e74c3c'}}>
                        <i className="fa fa-exclamation-triangle fa-fw innerR half"/>
                        There are unsaved changes.
                    </div>
                </div>
            </Sticky>
        )
    }
}


export default PageHeading;
