import React from "react";

import Settings from './../../common/stores/Settings';
import BroadcastForm from './../../common/components/broadcast/BroadcastForm'

export default class BroadcastPage extends React.Component {

    componentDidMount() {
        document.title = "Announcements | " + Settings.getConfig().site_name;
    }

    render() {

        return (
            <div className="content">
                <BroadcastForm />
            </div>
        );
    }
}
