import React from 'react';
import ListenerUserStore from './../stores/ListenerUser';
import SettingsStore from './../stores/Settings';
import {Route, Switch} from 'react-router-dom';

import ApiClient from './../lib/ApiClient'

import LoginPage from '../../pages/weblistener/login/page';
import LogoutPage from '../../pages/weblistener/logout';
import ListenerRestrictedArea from './ListenerRestrictedArea';
import WebListener from '../../pages/weblistener/listener';
import {WEB_LISTENER_API_BASE_PATH, WEB_LISTENER_URL_BASE_PATH} from '../lib/constants';
import {trim} from 'lodash';


class ListenerApp extends React.Component {
    constructor(props) {
        super(props);

        this.postLogin          = this.postLogin.bind ( this );
        this.postLogout         = this.postLogout.bind( this )
        this.postConfigUpdate   = this.postConfigUpdate.bind( this );

        this.state = {
            config: SettingsStore.getConfig(),
            logoutRedirect: false
        }
    }

    postLogin() {
        if ( ListenerUserStore.isLoggedIn() ) {
            window.location = `${WEB_LISTENER_URL_BASE_PATH}/`
        }
    }

    postLogout() {
        this.setState( {
            logoutRedirect: true
        });
    }

    postConfigUpdate() {
        this.setState({
            config: SettingsStore.getConfig()
        })
    }

    checkJwt() {
        const jwt = ListenerUserStore.getJwt();
        if ( !jwt || jwt === '' ) {
            return;
        }

        ApiClient.post({
            endpoint: `${WEB_LISTENER_API_BASE_PATH}/auth/verify-jwt`,
        }, jwt ).then((res) => {
            ListenerUserStore.loginUser( jwt )
        }, () => {
            ListenerUserStore.logoutUser()
        });
    }

    componentWillMount() {
        this.checkJwt();
    }

    componentDidMount() {
        ListenerUserStore.subscribe(this.postLogout, 'logout');
        SettingsStore.subscribe(this.postConfigUpdate, 'update');

        document.title = `Web Listener Interface | ${SettingsStore.getConfig().site_name}`
    }

    componentWillUnmount() {
        ListenerUserStore.unsubscribe(this.postLogout, 'logout');
        SettingsStore.unsubscribe(this.postConfigUpdate, 'update');
    }

    render() {
        let style = {};
        style.paddingLeft = 0;

        let basePath = `/${trim(WEB_LISTENER_URL_BASE_PATH, '/')}`;

        return (
            <div id="page-wrapper" className="page-wrapper l-web-listener" style={style}>
                <div className="page-content" id="page-content-wrapper">
                    <Switch>
                        <Route path={`${basePath}/login`} component={LoginPage} />
                        <Route path={`${basePath}/logout`} component={LogoutPage} />
                        <ListenerRestrictedArea path={`${basePath}`} component={WebListener} />

                        <Route component={NoMatch}/>
                    </Switch>
                </div>
            </div>
        );
    }
}

const NoMatch = ({ location }) => (
    <div>
        <h3>The page you are looking for can not be found: <code>{location.pathname}</code></h3>
    </div>
)


export default ListenerApp;

