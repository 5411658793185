
import React from "react";
import ReactTable from 'react-table';
import CustomPaginationComponent from './../../common/lib/ReactTable-Pagination';

// Imports from the charts library
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts'
import humanize from "humanize-duration";


const colors = require("./../../common/lib/colors.json");


const style = {
    top: 0,
    left: 350,
    lineHeight: '24px'
};

class ActiveUsersByRegionChart extends React.Component {
    render() {
        const {data} = this.props;
        let chartData = [];

        if ( data && data.region ) {
            for ( let i in data.region ) {
                chartData.push({
                    name: data.region[i].region ? data.region[i].region : 'Unknown',
                    value: data.region[i].active_users,
                    fill: colors.stats[1]
                });
            }
        }

        const columns = [

            {
                Header: 'Region',
                accessor: 'name',
                sortable: true,
                filterable: true,
                Cell: row => {
                    return (
                        <div>
                            <strong>{row.value}</strong>
                        </div>
                    )
                }
            },

            {
                Header: 'Active users',
                accessor: 'value',
                sortable: true,
                width: 200,
                /*className: 'text-center',
                headerClassName: 'text-center',
                filterable: true*/
            }];

        return (
            <div className="col-lg-12" style={{ height: 'auto'}}>
                <ReactTable
                    PaginationComponent={CustomPaginationComponent}
                    showPageSizeOptions={true}
                    pageSizeOptions={[10, 25, 50, 100, 200]}
                    className=""
                    paginationClassName="pagination-filters clearfix"
                    data={chartData}
                    columns={columns}
                    defaultPageSize={25}
                    minRows={1}
                />
            </div>
        );


        /*return (
            <div className="col-lg-12" style={{ height: '450px'}}>
                <ResponsiveContainer>
                    <BarChart data={chartData} layout="vertical"
                              margin={{top: 5, right: 30, left: 20, bottom: 5}}>

                        <YAxis dataKey="name" type="category"
                               width={250}
                               interval={0}

                               allowDataOverflow={true} />

                        <XAxis height={50} label={{ value: "Number of active users", position: 'insideBottom' }}
                               domain={[0, 'dataMax + 80']}
                               type={"number"} />

                        <Tooltip/>
                        <Bar dataKey="value"  name='Active users' />
                    </BarChart>

                </ResponsiveContainer>
            </div>
        );*/
    }
}

// Export example
export default ActiveUsersByRegionChart;