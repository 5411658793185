import React from 'react';

import Dropzone from 'react-dropzone';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalTitle from 'react-bootstrap/ModalTitle';
import lodash from "lodash";

import ApiClient from "../../common/lib/ApiClient";
import Notifications from "../../common/lib/Notifications";
import UsersStore from "../../common/stores/Users";
import UserStore from "../../common/stores/User";

class ManageBuild extends React.Component {

    constructor(props) {
        super(props)

        this.showAddBuild           = this.showAddBuild.bind(this)
        this.hideAddBuild           = this.hideAddBuild.bind(this)
        this.handleChange           = this.handleChange.bind(this)
        this.postUsersStoreUpdate   = this.postUsersStoreUpdate.bind(this)


        this.state = {
            addBuild: !!(props && props.data !== null ),
            sha1Error: false,
            files: [],
            users: null,
            data: props && props.data !== null ? props.data : null
        }
    }

    validateSha1(event) {
        const regex = /^([0-9a-fA-F]{2}:){19}[0-9a-fA-F]{2}$/;
        let new_sha1_fingerprint = event.target.value;

        this.setState({sha1Error: !regex.test(new_sha1_fingerprint)})
    }

    handleChange(event) {

        let value = {};
        value[event.target.name] = event.target.value;

        if ( 'sha1_fingerprint' === event.target.name ) {
            value[event.target.name] = lodash.toUpper( value[event.target.name] );
        }

        let {data} = this.state;
        if ( !data ) {
            data = {};
        }
        lodash.assign ( data, value )

        this.setState ( { data: data, dataChanged: true }  );
    }



    handleFileDropped (files) {
        this.setState({files: (!files || !files[0]) ? [] : files})
    }

    submit () {
        const {files} = this.state;


        if (!files || !files[0] || !this.state.data || !this.state.data.user_id || !this.state.data.sha1_fingerprint ) {
            Notifications.showNotific8(
                'Please make sure all fields are set (customer, SHA1 fingerprint, application file)',
                'error', {
                    closeInSeconds: 5
                });
            return false;
        }

        // Uploading
        this.setState( { uploadingFile: true } )

        // do whatever you want with the file content
        const req = ApiClient.getPostRequestObject('/api/v1/build/file');

        req.attach('application_file', files[0])
            //.query('platform=android&user_id=' + this.state.data.user_id)
            .field('platform', 'android')
            .field('user_id', this.state.data.user_id)
            .field('sha1_fingerprint', this.state.data.sha1_fingerprint)
            .end((err, res) => {

                if ( err || !res.ok ) {
                    Notifications.showNotific8(
                        'Error occured during upload: ' + (res.body && res.body.error ? res.body.error : 'Server error' ),
                        'error', {
                            closeInSeconds: 10
                        });

                    this.setState({ uploadingFile: false });

                    return true;
                }

                this.setState({ uploadingFile: false }, () => {


                    this.hideAddBuild();
                })
            })
    }

    showAddBuild() {
        this.setState( { addBuild: true } )
    }

    hideAddBuild() {
        this.setState( {
            addBuild: false,
            sha1Error: false,
            files: [],
            users: null,
            data: null
        }, function() {
            if ( this.props.onChanged ) {
                this.props.onChanged()
            }
        } )
    }

    postUsersStoreUpdate() {
        const users = UsersStore.getUsers();
        this.setState({ users: users })
    }

    componentDidMount(props) {

        // Subscribe to update events
        UsersStore.subscribe( this.postUsersStoreUpdate );

        // Load data
        UsersStore.loadFromServer();
    }

    componentWillUnmount() {
        UsersStore.unsubscribe(this.postUsersStoreUpdate);

    }

    componentWillReceiveProps(props) {
        this.setState( { addBuild: !!(props && props.data !== null && props.data)} )
    }

    render() {
        let dropzoneRef;
        let { users} = this.state;

        return (
            <div className="clearfix">

                <Modal show={this.state.addBuild} onHide={this.hideAddBuild}>
                    <ModalHeader closeButton={true} onHide={this.hideAddBuild}>
                        <ModalTitle>&nbsp;Upload Android APK file</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        {!this.state.uploadingFile ? null :
                        <div className="modal-loading"><p>The file is uploading, please wait. <span><i
                            className="fa fa-spin fa-spinner"></i></span></p></div>
                        }
                        <div className="form-horizontal">
                            <p className="size14">Before you upload the first APK file, you must configure the SHA1 Fingerprint. It is used
                                to check that the application file you are uploading is legitimate.</p>

                            <p className="size14">Once set, the SHA1 Fingerprint can not be changed. The reseller build must be deleted from
                                the system in order to be able to set a new SHA1 Fingerprint value.
                            </p>

                            <div className="">
                                <div className="marginB half">
                                    <label className=" control-label">Customer</label>
                                    <div className="">
                                        {users === null ? <i className="fa fa-spin fa-spinner"/> :
                                            <select className="form-control input-sm marginT half"
                                                    name="user_id"
                                                    value={this.state.data && this.state.data.user_id ? this.state.data.user_id : ''}
                                                    disabled={this.props.data && this.props.data.id}
                                                    onChange={this.handleChange}>
                                                <option value={''}>- choose user -
                                                </option>
                                                {users.map((d) => <option key={'dou' + d.id}
                                                                          value={d.id}>{d.username}</option>)}
                                            </select>
                                        }
                                    </div>
                                </div>

                                <div className="marginB half">
                                    <label className="control-label">SHA1 Fingerprint</label>
                                    <div className="">
                                        <input type="text"
                                               className="form-control input-sm marginT half"
                                               name="sha1_fingerprint"
                                               autoComplete="off"
                                               minLength="3"
                                               disabled={this.props && this.props.data && this.props.data.id}
                                               value={this.state.data && this.state.data.sha1_fingerprint || ''}
                                               onBlur={this.validateSha1.bind(this)}
                                               onChange={this.handleChange}
                                        />
                                        {!this.state.sha1Error ? null :
                                            <p className={'size14 innerT half text-danger'}>The SHA1 Fingerprint you have entered is not valid.</p>
                                        }
                                    </div>
                                </div>

                                <div className="marginB half">
                                    <label className="control-label">Application file (APK accepted only)</label>
                                    <div>
                                        <Dropzone className="hidden"
                                                  ref={(node) => { dropzoneRef = node; }} onDrop={this.handleFileDropped.bind(this)}>
                                        </Dropzone>
                                    </div>

                                    {!this.state.files[0] ?
                                    <button type="button" className="btn btn-default btn-sm marginT half"
                                            disabled={!(this.state.data && this.state.data.sha1_fingerprint) || this.state.sha1Error}
                                            onClick={() => { dropzoneRef.open() }}>

                                        {this.props && this.props.data && this.props.data.id ? "Replace existing version" : "Choose file"}
                                    </button>
                                        :
                                        <div className={'marginT half'}>
                                            <span>{this.state.files[0] ? this.state.files[0].name : 'n/a'}</span>
                                        <a href={'javascript:;'} className="text-danger marginL inline-block"
                                                onClick={() => { this.setState({files:[]}) }}>
                                            <i className="fa fa-times fa-fw"/> Remove file
                                        </a>
                                        </div>
                                    }
                                </div>

                                <div className="border-top innerT marginT ">
                                    <button type="button"
                                            disabled={!(this.state.data && this.state.data.sha1_fingerprint) || this.state.sha1Error}
                                            className="btn btn-warning" onClick={this.submit.bind(this)}>
                                        <i className="fal fa-cloud-upload fa-fw marginR half"/> Save APK file</button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default ManageBuild