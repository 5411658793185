import moment from 'moment-timezone';
import React from 'react';

const formatFileSize = (file_size) => {
    let bytes = file_size;

    if(bytes === 0) return '0 Bytes';

    let k = 1024,
        dm = 0,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
};

const formatTime = (value, timezone) => {

    var c = moment.unix(value).tz(timezone);
    return ( <span>{c.format('HH:mm MMM D, YYYY')}</span> )
}

const secondsToHuman = (totalSeconds) => {
    var hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    var minutes = Math.floor(totalSeconds / 60);
    var seconds = totalSeconds % 60;

    let human = "";
    if (hours > 0) {
        human += hours + " hours, ";
    }
    if (minutes > 0) {
        human += minutes + " minutes, ";
    }

    human += seconds + " seconds";

    return human
}

const secondsToDuration = (totalSeconds) => {
    var hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    var minutes = Math.floor(totalSeconds / 60);
    var seconds = totalSeconds % 60;

    let human = "";
    if (hours > 0) {
        human += hours + ":";
    }
    /*if (minutes > 0) {
        human += minutes + ":";
    }*/

    human += (minutes < 10 && minutes >= 0 ? '0' + minutes : minutes) + ":";
    human += (seconds < 10 && seconds >= 0 ? '0' + seconds : seconds) + "";

    return human
}

export {
    formatTime,
    formatFileSize,
    secondsToDuration,
    secondsToHuman,
}