import React from 'react';

import _ from 'lodash';

import Select from 'react-select'

import StatsDateRangePicker from './daterangepicker';
import ApiClient from "../../common/lib/ApiClient";
import SettingsStore from "../../common/stores/Settings";
import moment from 'moment-timezone'

import ReactTable from 'react-table';
import CustomPaginationComponent from './../../common/lib/ReactTable-Pagination';
import UserStore from "../../common/stores/User";

const colors = require("./../../common/lib/colors.json");

class EventsLog extends React.Component {
    state = {
        loaded: false,
        timerangeFilter: null,
        regionFilter: null,
        accountFilter: null,
        regions: null,
        data: []
    }

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        document.title = "Reports - Events Log | " + SettingsStore.getConfig().site_name;

        this.fetchFilters()
        this.fetchData()
    }

    fetchFilters() {
        this.setState({loading: true});

        ApiClient.get({
            endpoint: '/api/v1/analytics/filters'

        }).then(res => {
            // Data
            let filters = res.body.data && res.body.data.filters ? res.body.data.filters : null;

            this.setState({
                regions: filters && filters.region ? filters.region : null,
                users: filters && filters.users ? filters.users : null,
                loaded: true,
                loading: false
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    fetchData() {
        this.setState({loading: true})

        ApiClient.get({
            endpoint: '/api/v1/analytics/event-log',
            query: {
                timerange: this.state.timerangeFilter,
                filters: {
                    region: this.state.regionFilter,
                    username: this.state.accountFilter
                }
            }

        }).then(res => {

            this._dataList = res.body.data;

            this.setState({
                data: res.body.data,
                loaded: true,
                loading: false
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    handleFilterTimerangeChange = (startDate,endDate) => {

        let newts = ( startDate.unix() ).toString() + ':';
        if ( endDate ) {
            newts = newts + ( endDate.unix() ).toString();
        }

        this.setState({
            timerangeFilter: newts
        }, () => {
            this.fetchData()
        })
    }

    render() {
        const {regions, users, regionFilter, accountFilter} = this.state;

        let selectRegions = [];
        if ( regions ) {
            for ( var i in regions ) {
                selectRegions.push({
                    value: regions[i],
                    label: regions[i]
                })
            }
        }

        let {timezone} = UserStore.getUser();
        timezone = timezone ? timezone : 'UTC';

        const columns = [
            {
                Header: 'Event type',
                sortable: true,
                accessor: 'stat_key',
                className: 'text-left',
                headerClassName: 'text-left',
                width: 250,
                filterable: true,
                filterMethod: (filter, row) => {
                    if (filter.value === 'all') {
                        return true
                    }

                    return row.stat_key == filter.value;
                },
                Filter: ({ filter, onChange }) => {
                    return(<select
                        autoComplete="off"
                        onChange={event => onChange(event.target.value)}
                        style={{ width: '100%' }}
                        value={filter ? filter.value : 'all'}
                    >
                        <option value="all">All</option>
                        <optgroup label="Messages">
                            <option value="message_sent">Sent </option>
                            <option value="message_received">Received</option>
                        </optgroup>
                        <optgroup label="Files">
                            <option value="file_sent">Sent</option>
                            <option value="file_received">Received</option>
                        </optgroup>
                        <optgroup label="Calls">
                            <option value="call_incoming">Incoming</option>
                            <option value="call_outgoing">Outgoing</option>
                        </optgroup>

                        {/*<option value="users_online">Online Users</option>*/}
                    </select>)
                },

                Cell: (row) => {
                    let rclass = "text-uppercase";
                    let label = "";
                    let style = {};

                    if ( 0 === row.value.indexOf('message_') ) {

                        style.color = colors.stats[0]
                    }

                    if ( 0 === row.value.indexOf('file_') ) {

                        style.color = colors.stats[4]
                    }

                    if ( 0 === row.value.indexOf('call_') ) {

                        style.color = colors.stats[3]
                    }
                    return ( <span className={rclass} style={style}>{row.value.replace('_', ' ')}</span> )
                }
            },

            {
                Header: 'Timestamp',
                sortable: true,
                accessor: 'ts',
                className: 'text-left',
                headerClassName: 'text-left',
                width: 200,
                Cell: row => {
                    if ( !row.value ) {
                        return null;
                    }

                    var c = moment(row.value).tz(timezone);
                    return ( <span>{c.format('HH:mm:ss MMM D, YYYY')}</span> )
                }
            },

            {
                Header: 'Username',
                accessor: 'username',
                sortable: true,
                filterable: false
            }];


        let dataList = this.state.data;

        return (
            <div>
                <h1>Events Log</h1>

                <div className={'marginB bg-white content'}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <h4 className="margin-top-none">Filters</h4>
                        </div>
                        <div className={'col-md-12'}>
                            <div className="pull-left" style={{width: '400px'}}>
                                <StatsDateRangePicker
                                    onChange={this.handleFilterTimerangeChange}
                                    dateLimit={{ days: 7 }}
                                    ranges={{
                                        'Today': [moment(), moment()],
                                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'Last 7 Days': [moment().subtract(6, 'days'), moment()]
                                    }}
                                />
                            </div>

                            <div className="pull-left marginL" style={{width: '300px'}}>
                                <Select name={'region'}
                                        placeholder={'Filter by region'}
                                        options={selectRegions}
                                        className="stats-filter"
                                        classNamePrefix="select"
                                        isClearable
                                        isSearchable
                                        defaultValue={regionFilter}
                                        onChange={(ev) => {
                                            if ( ev && ev.value ) {
                                                this.setState({
                                                    regionFilter: ev.value
                                                }, () => {
                                                    this.fetchData()
                                                })
                                            } else {
                                                this.setState({
                                                    regionFilter: null
                                                }, () => {
                                                    this.fetchData()
                                                })
                                            }
                                        }}
                                />
                            </div>

                            <div className="pull-left marginL" style={{width: '300px'}}>
                                <Select name={'username'}
                                        placeholder={'Filter by username'}
                                        options={users}
                                        className="stats-filter"
                                        classNamePrefix="select"
                                        isClearable
                                        isSearchable
                                        defaultValue={accountFilter}
                                        onChange={(ev) => {
                                            if ( ev && ev.value ) {
                                                this.setState({
                                                    accountFilter: ev.value
                                                }, () => {
                                                    this.fetchData()
                                                })
                                            } else {
                                                this.setState({
                                                    accountFilter: null
                                                }, () => {
                                                    this.fetchData()
                                                })
                                            }
                                        }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content">

                    <div>
                        <ReactTable
                            PaginationComponent={CustomPaginationComponent}
                            showPageSizeOptions={true}
                            pageSizeOptions={[10, 25, 50, 100, 200]}
                            className=""
                            paginationClassName="pagination-filters clearfix"
                            data={dataList}
                            columns={columns}
                            defaultPageSize={10}
                            minRows={1}
                            loading={!this.state.loaded || this.state.loading}
                        />

                    </div>
                </div>
            </div>
        )
    }
}

export default EventsLog;