
import React from 'react'
import {
    Route,
    Link,
    Redirect,
    withRouter
} from 'react-router-dom'

import UserStore from './../stores/User';

const PrivateAreaRouter = ({ component: Component, ...rest }) => {
    return (<Route {...rest} render={props => (
        UserStore.isLoggedIn() ? (
                <Component {...rest} {...props}/>
            ) : (
                null
                /*<Redirect to={{
                    pathname: '/login',
                    state: { from: props.location }
                }}/>*/
            )
    )}/>
)}

export default withRouter(PrivateAreaRouter);
