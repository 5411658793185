import React, {Component, Fragment, PureComponent} from "react";

import _ from "lodash";

import ApiClient from "../../common/lib/ApiClient";
import FormField from "../../common/components/FormField";
import PasswordField from "../../common/components/PasswordField";
import Button from "react-bootstrap/Button";
import Notifications from "../../common/lib/Notifications";






class ChangePassword extends Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this)

        this.state = {
            loading: false,
            password: '',
            passwordConfirmation: '',
            oldPassword: '',
        }
    }

    handleSubmit() {
        const {
            closeChangePassword,
            subscription
        } = this.props;
        
        const {
            password
        } = this.state;

        this.setState({loading: true})

        ApiClient.post({
            endpoint: '/api/v1/subscription/' + subscription.id + '/change-password',
            data: {
                password                
            }

        }).then(res => {
            this.setState({
                loading: false,
            }, () => {
                closeChangePassword && closeChangePassword()
            })

            Notifications.showNotific8(
                'The user password has been updated.',
                'success', {
                    closeInSeconds: 5
                })

        }, (err) => {
            console.log(err)
            this.setState({
                loading: false,
                error: err
            })

            ApiClient.onError(err)
        })
    }

    fieldStateChanged = field => state => this.setState({ [field]: state.errors.length === 0 ? state.value : '' });

    passwordChanged = this.fieldStateChanged('password');
    passwordConfirmationChanged = this.fieldStateChanged('passwordConfirmation');
    oldPasswordChanged = this.fieldStateChanged('oldPassword');

    render() {
        const {
            closeChangePassword
        } = this.props;

        const {
            loading,
            password,
            passwordConfirmation
        } = this.state;

        const validatePasswordConfirmation = value => {
            if (value !== password) {
                throw new Error('The passwords do not match')
            }
        };

        const canSubmit = !!(password && passwordConfirmation /*&& oldPassword*/);

        return (
            <div>



                {/*<FormField type="password"
                           className="mb-3"
                           fieldId="oldPassword"
                           label="Current password"
                           placeholder="enter the current password"
                           onStateChanged={this.oldPasswordChanged}
                           required
        />*/}
        {/*<div>TESTING: V3</div>*/}


                <PasswordField label={"New password"}
                               fieldId={"password"}
                               placeholder={"enter new password"}
                               required={true}
                               thresholdLength={8}
                               minStrength={1}
                               onStateChanged={this.passwordChanged}
                               autoComplete="new-password"
                />

                <FormField type="password"
                           fieldId="passwordConfirmation"
                           label="Confirm new password"
                           placeholder="enter again the new password"
                           disabled={!password}
                           validator={validatePasswordConfirmation}
                           onStateChanged={this.passwordConfirmationChanged} required 
                           autoComplete="new-password"/>

                <div className="form-group px-3 pt-2">
                    {!loading ? null :
                    <p className="text-muted-darker"><i className="fa fa-spin fa-spinner" /> &nbsp;&nbsp; Updating the password
                        can take a few minutes, please wait </p>
                    }
                    <Button variant="warning" className="mr-4" disabled={loading || !canSubmit} onClick={loading ? null : this.handleSubmit}>
                        Save new password
                    </Button>
                    <Button variant="light" disabled={loading} onClick={loading ? null : closeChangePassword}>
                        Close
                    </Button>
                </div>
            </div>
        )
    }
}

export default ChangePassword;
