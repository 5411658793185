import React, {useEffect, useState} from 'react';
import ApiClient from '../../common/lib/ApiClient';
import {WEB_LISTENER_API_BASE_PATH, WEB_LISTENER_URL_BASE_PATH} from '../../common/lib/constants';
import ListenerUser from '../../common/stores/ListenerUser';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import VideoStream from './video/video-stream';

const getAllRemotes = async () => {
  try {
    const response = await ApiClient.get({
      endpoint: `${WEB_LISTENER_API_BASE_PATH}/remote`
    }, ListenerUser.getJwt())

    if (!response.ok) {
      return null;
    }

    if (!response.body.data) {
      return null;
    }

    return response.body.data;

    // return remotes;
  } catch (err) {
    console.log('Error when fetching remotes');
    console.error(err);

    return null;
  }
}

const WebListener = () => {

  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [remotes, setRemotes] = useState([]);
  const [activeUser, setActiveUser] = useState(null);
  const [activeCallType, setActiveCallType] = useState(null);

  // Retrieve the online users
  useEffect(() => {
    setLoading(true);
    getAllRemotes().then((newRemotes) => {
      if (newRemotes) {
        setRemotes(newRemotes);
        setLoading(false);
        setLoaded(true);
      }
    })
  }, []);

  const startCall = (remote, type) => {
    // If it's the same user and same call type, hang up
    if (activeUser === remote && activeCallType === type) {
      handleCallEnded();
      return;
    }

    if (activeUser !== null) {
      const confirmCall = window.confirm('Starting a new call will stop the current call. Are you sure you want to continue?');
      if (!confirmCall) {
        return;
      }
    }

    setActiveCallType(type);
    setActiveUser(remote);
  }

  const handleCallEnded = () => {
    setActiveCallType(null);
    setActiveUser(null);
  }

  const userData = ListenerUser.getUser();
  const currentListener = userData.display_name || userData.sip_username;

  const hasHDVidFeature = false;

  return (
    <div className="bg-white listener-container">
      <div className="flex-row d-flex h-100">
        <div className="col-4 px-0 listener-sidebar">
          <div className="bg-orange px-4 py-4 remotes-heading">
            <h4 className="text-weight-300 mb-0 size20"><i className="fal fa-user-friends mr-2" /> Users</h4>
          </div>
          <div className="current-user px-3 py-3 border-bottom size14 d-flex flex-row justify-content-between">
            <div>Welcome {currentListener}!</div>
            <div>
              <Link to={`${WEB_LISTENER_URL_BASE_PATH}/logout`} className="text-orange">
                Logout
              </Link>
            </div>
          </div>
          <ul className="remotes-list">
            {remotes.map(remote => (
              <li key={remote.id}>
                <div className={`${activeUser === remote.sip_username ? 'current' : ''}`}>
                  {remote.sip_username}
                </div>
                <div>
                  <span
                    onClick={() => startCall(remote.sip_username, 'audio')}
                    className={`mr-3 cursor-pointer ${activeUser === remote.sip_username && activeCallType === 'audio' ? 'text-orange' : ''}`}
                    title="Start a voice call"
                  >
                    <i className="fa fa-phone" />
                  </span>
                  <span
                    onClick={() => startCall(remote.sip_username, 'video')}
                    className={`mr-3 cursor-pointer ${activeUser === remote.sip_username && activeCallType === 'video' ? 'text-orange' : ''}`}
                    title="Start a video call"
                  >
                    <i className="fa fa-video" />
                  </span>
                    { hasHDVidFeature && 
                        <span
                            onClick={() => startCall(remote.sip_username, 'video-hd')}
                            className={`text-weight-bold cursor-pointer size14 ${
                                activeUser === remote.sip_username && activeCallType === 'video-hd' ? 'text-orange' : ''
                            }`}
                            title="Start HD video call"
                            >
                            HD
                        </span>
                    }
                </div>
              </li>
            ))}
            {(!remotes || !remotes.length) && !loading && loaded && (
              <li className="justify-content-center mt-4 text-muted">No remotes available</li>
            )}
            {loading && <li className="justify-content-center mt-4 text-muted"><Spinner animation="border" /></li>}
          </ul>
          {/*
          <div className="copyright-note">
            <a href="https://www.sptk.com/" target="_blank" rel="noopener noreferrer" className="text-orange">
              SpectraCall
            </a> &copy; {new Date().getFullYear()} All Rights Reserved
          </div>
            */}
        </div>

        <div className="col-8 px-0 bg-light">
            {activeUser && (
              <VideoStream
                key={`${activeUser}-${activeCallType}`}
                listenerUsername={userData.sip_username}
                listenerHa1={userData.ha1}
                remoteUsername={activeUser}
                callType={activeCallType}
                onCallEnded={handleCallEnded} />
            )}
        </div>
      </div>
    </div>
  )
}

export default WebListener;
