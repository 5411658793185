import React from 'react'
import { Redirect } from 'react-router-dom'
import UserStore from './../stores/User'

class LogoutPage extends React.Component {

    componentWillMount() {
        UserStore.logoutUser();
    }

    render() {
        return (
            <Redirect to="/login"/>
        )
    }
}

/*LogoutPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired
}*/

export default LogoutPage
