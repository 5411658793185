import React from 'react';

import _ from 'lodash';

import Select from 'react-select'

import StatsDateRangePicker from './daterangepicker';
import ApiClient from "../../common/lib/ApiClient";
import SettingsStore from "../../common/stores/Settings";
import moment from 'moment-timezone'

import PieAppVersion from './pie-app-version';
import UserStore from "../../common/stores/User";
import Card from "react-bootstrap/Card";



class AppVersionReport extends React.Component {
    state = {
        loaded: false,
        regionFilter: null,
        regions: null,
        data: []
    }

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        document.title = "Reports - App Version | " + SettingsStore.getConfig().site_name;

        this.fetchFilters()
        this.fetchData()
    }

    fetchFilters() {
        this.setState({loading: true});

        ApiClient.get({
            endpoint: '/api/v1/analytics/filters'

        }).then(res => {
            // Data
            let filters = res.body.data && res.body.data.filters ? res.body.data.filters : null;

            this.setState({
                regions: filters && filters.region ? filters.region : null,
                loaded: true,
                loading: false
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    fetchData() {
        this.setState({loading: true})

        ApiClient.get({
            endpoint: '/api/v1/analytics/report/app-version',
            query: {
                filters: {
                    region: this.state.regionFilter
                }
            }

        }).then(res => {

            this._dataList = res.body.data;

            this.setState({
                data: res.body.data,
                loaded: true,
                loading: false
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    render() {
        const {regions, users, regionFilter, accountFilter} = this.state;

        let selectRegions = [];
        if ( regions ) {
            for ( var i in regions ) {
                selectRegions.push({
                    value: regions[i],
                    label: regions[i]
                })
            }
        }

        let {timezone} = UserStore.getUser();
        timezone = timezone ? timezone : 'UTC';

        const columns = [

            {
                Header: 'Username',
                accessor: 'username',
                sortable: true,
                filterable: false
            },



            {
                Header: 'Event type',
                sortable: false,
                accessor: 'stat_key',
                className: 'text-right',
                headerClassName: 'text-right',
                width: 230,
                filterable: true,
                filterMethod: (filter, row) => {
                    if (filter.value === 'all') {
                        return true
                    }

                    return row.stat_key == filter.value;
                },
                Filter: ({ filter, onChange }) => {
                    return(<select
                        autoComplete="off"
                        onChange={event => onChange(event.target.value)}
                        style={{ width: '100%' }}
                        value={filter ? filter.value : 'all'}
                    >
                        <option value="all">All</option>
                        <optgroup label="Messages">
                            <option value="message_sent">Sent </option>
                            <option value="message_received">Received</option>
                        </optgroup>
                        <optgroup label="Files">
                            <option value="file_sent">Sent</option>
                            <option value="file_received">Received</option>
                        </optgroup>
                        <optgroup label="Calls">
                            <option value="call_incoming">Incoming</option>
                            <option value="call_outgoing">Outgoing</option>
                        </optgroup>

                        {/*<option value="users_online">Online Users</option>*/}
                    </select>)
                },
                Cell: (row) => {
                    let rclass = "text-uppercase";
                    let label = "";

                    if ( 0 === row.value.indexOf('message_') ) {
                        rclass+= " text-info"
                    }

                    if ( 0 === row.value.indexOf('file_') ) {
                        rclass+= " text-primary"
                    }

                    if ( 0 === row.value.indexOf('call_') ) {
                        rclass+= " text-warning"
                    }
                    return ( <span className={rclass}>{row.value.replace('_', ' ')}</span> )
                }
            }];


        let dataList = this.state.data;

        return (
            <div>
                <h1>Charts</h1>

                <div className={'marginB bg-white content'}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <h4 className="margin-top-none">Filters</h4>
                        </div>
                        <div className={'col-md-12'}>

                            <div className="pull-left " style={{width: '300px'}}>
                                <Select name={'region'}
                                        placeholder={'Filter by region'}
                                        options={selectRegions}
                                        className="stats-filter"
                                        classNamePrefix="select"
                                        isClearable
                                        isSearchable
                                        defaultValue={regionFilter}
                                        onChange={(ev) => {
                                            if ( ev && ev.value ) {
                                                this.setState({
                                                    regionFilter: ev.value
                                                }, () => {
                                                    this.fetchData()
                                                })
                                            } else {
                                                this.setState({
                                                    regionFilter: null
                                                }, () => {
                                                    this.fetchData()
                                                })
                                            }
                                        }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="">
                    <div className="row">
                        <div className="col-md-6">
                            <Card className="marginB">
                                <Card.Body>
                                <Card.Title>Application Version</Card.Title>

                                <div style={{height: '400px'}}>
                                    <PieAppVersion data={dataList} />
                                </div>
                                </Card.Body>

                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AppVersionReport;