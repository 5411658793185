
import React from "react";
import _ from "underscore";
import moment from "moment";

// Pond
import { TimeSeries, Index, TimeRange } from "pondjs";

// Imports from the charts library
import {
    ChartContainer,
    ChartRow,
    Charts,
    YAxis,
    BarChart,
    Resizable,
    Legend,
    MultiBrush
} from "react-timeseries-charts";

import styler from "../../../node_modules/react-timeseries-charts/lib/js/styler";

import ApiClient from "../../common/lib/ApiClient";


import Card from "react-bootstrap/Card"

const colors = require("./../../common/lib/colors.json");


const MAX_ZOOM_TIMERANGE = 1000 * 60 * 60 * 3;
const style = styler([
    { key: "users_online", color: colors.stats[1], selected: colors.stats[3] }
]);

const trackerStyle = {
    line: {
        stroke: "#000000",
        cursor: "crosshair",
        pointerEvents: "none"
    }
};

class UsersOnline extends React.Component {

    _isMounted = false

    constructor(props) {
        super(props);
        this.handleRescale = _.debounce(this.rescale, 300);

        this.fetchData = this.fetchData.bind(this)

        this.state = {
            loading: false,
            loaded: false,
            forceReload: false,
            tracker: null,
            timerange: null,
            selected: 1,
            selections: []
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchData()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        if(this.props.timerangeFilter !== prevProps.timerangeFilter)
        {
            if (this._isMounted) {
                this.fetchData();
            }
        } else {

            if(JSON.stringify(this.props.filters) !== JSON.stringify( prevProps.filters ))
            {
                if (this._isMounted) {
                    this.fetchData();
                }
            }
        }
    }

    fetchData() {
        const {timerangeFilter,filters} = this.props;

        this.setState({loading: true});

        ApiClient.get({
            endpoint: '/api/v1/analytics/statistics/users-online',
            query: {
                filters: filters,
                timerange: timerangeFilter
            }

        }).then(res => {
            // Data
            const responseData = res.body.data;
            
            const users_online = [];

            _.each(responseData, val => {
                const timestamp = moment(new Date(`${val["time"]}`));
                const onlineUsers = val["users_online"];
                
                
                users_online.push([timestamp.toDate().getTime(), onlineUsers]);
            });

            const users_onlineSeries = new TimeSeries({
                name: "users_online",
                columns: ["index", "users_online"],
                points: users_online.map(([d, value]) => [
                    Index.getIndexString("1h", new Date(d)),
                    value
                ])
            });

            this.setState({
                data: res.body.data,
                loaded: true,
                loading: false,
                timerange: users_onlineSeries.range(),

                users_onlineSeries,
            })

        }, (err) => {
            ApiClient.onError(err)
        })

    }

    rescale(timerange) {
        let max = 100;
        const {users_onlineSeries} = this.state;
        const usersOnline = users_onlineSeries.crop(this.state.timerange).max("users_online");
        if (usersOnline > max) max = usersOnline;
        this.setState({ max });
    }

    handleTimeRangeChange = timerange => {
        if ( timerange.duration() < MAX_ZOOM_TIMERANGE ) {
            return false;
        }
        this.setState({ timerange });
        this.handleRescale(timerange);
    };


    render() {
        const {loading, loaded} = this.state;
        if ( !loaded || loading ) {
            return (
                <div>
                    <Card className="marginB  panel-stats">
                        <Card.Body>
                        <Card.Title>Online users</Card.Title>
                        <div>
                            <div className="innerAll half text-center marginAll">Loading
                                &nbsp;
                                <i className={'fa fa-spin fa-spinner fa-1x '}/>
                            </div>
                        </div>
                        </Card.Body>
                    </Card>
                </div>
            );
        }
        const dateStyle = {
            fontSize: 12,
            color: "#AAA",
            borderWidth: 1,
            borderColor: "#F4F4F4"
        };

        const markerStyle = {
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            color: "#AAA",
            marginLeft: "5px"
        };

        const {users_onlineSeries} = this.state;
        const max = users_onlineSeries.crop(this.state.timerange).max("users_online");

        const axistype = "linear";
        const tracker = this.state.tracker ? `${this.state.tracker}` : "";

        const highlight = this.state.highlight;
        let infoValues = [];
        if (highlight) {
            const trafficText = highlight.event.get(highlight.column);
            infoValues = [{ label: "Online users", value: trafficText.toString() }];
        }

        return (
            <div>
                <Card className="marginB panel-stats">
                    <Card.Body>
                    <Card.Title>Users online</Card.Title>
                    <div>

                        <div className="row">
                            <div className="col-md-12">

                                <Resizable>
                                    <ChartContainer padding={20}
                                                    paddingTop={5}
                                                    utc={true}
                                                    timeRange={this.state.timerange}
                                                    minDuration={MAX_ZOOM_TIMERANGE}

                                                    onTimeRangeChanged={this.handleTimeRangeChange}
                                                    maxTime={users_onlineSeries.range().end()}
                                                    minTime={users_onlineSeries.range().begin()}
                                                    enableDragZoom

                                                    onBackgroundClick={() => this.setState({ selection: null })}
                                    >
                                        <ChartRow height="250">

                                            <YAxis
                                                id="onlineusers"
                                                label="Number of users online"
                                                min={0}
                                                max={max}
                                                type="linear"
                                                width="60"
                                            />

                                            <Charts>
                                                <BarChart
                                                    axis="onlineusers"
                                                    style={style}
                                                    spacing={1}
                                                    columns={["users_online"]}
                                                    series={users_onlineSeries}

                                                    info={infoValues}
                                                    infoWidth={150}
                                                    infoStyle={{ stroke: "#999", color: "#333", fill: "white", opacity: 1, pointerEvents: "none" }}
                                                    infoTimeFormat={index =>
                                                        moment(index.begin()).format("HH:mm Do MMM 'YY")
                                                    }
                                                    highlighted={this.state.highlight}
                                                    onHighlightChange={highlight =>
                                                        this.setState({ highlight })
                                                    }
                                                    selected={this.state.selection}
                                                    onSelectionChange={selection =>
                                                        this.setState({ selection })
                                                    }
                                                />
                                            </Charts>
                                        </ChartRow>
                                    </ChartContainer>
                                </Resizable>
                            </div>

                        </div>
                    </div>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

// Export example
export default UsersOnline;