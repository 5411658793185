import { Web } from 'sip.js';

const SipClient = {
  setupConnection: async ({
    serverHostname = null,
    username = null,
    ha1 = null,
    audio = true,
    video = true,
    disableLogging = false,
    videoElement = null,
    events = {
      onRegistered: () => {},
      onCallReceived: () => {},
      onCallAnswered: () => {},
      onServerConnect: () => {},
      onServerDisconnect: () => {},
    },
  }) => {
    const sipClientConfig = {
      username: '',
      ha1: '',
      serverHostname,

      socketServer: `wss://${serverHostname}:49151`,
      stunServers: { urls: [ `stun:${serverHostname}:19999` ] },
      turnServers: {
        urls: [ `turn:${serverHostname}:19999` ],
        username: 'voip',
        credential: 'voip',
        credentialType: 'password',
      },
    };

    // Set up configuration
    if (username && ha1) {
      sipClientConfig.username = username;
      sipClientConfig.ha1 = ha1;
    }

    let iceServers = [];
    if (sipClientConfig.stunServers) {
      iceServers = [...iceServers, sipClientConfig.stunServers];
    }
    if (sipClientConfig.turnServers) {
      iceServers = [...iceServers, sipClientConfig.turnServers];
    }

    // Options for SimpleUser
    const options = {
      aor: `sip:${sipClientConfig.username}@${sipClientConfig.serverHostname}`, // caller
      userAgentOptions: {
        authorizationUsername: sipClientConfig.username,
        authorizationHa1: sipClientConfig.ha1,
        autoStop: true,
        logBuiltinEnabled: !disableLogging,
        // logLevel: 'log', // warn, debug, log, error
        sessionDescriptionHandlerFactoryOptions: {
          peerConnectionConfiguration: {
            iceServers,
            iceCandidatePoolSize: 10,
            iceTransportPolicy: "all" 
          },
        },
      },
      media: {
        constraints: { audio, video },
        remote: { video: videoElement },
      },
    };

    // WebSocket server to connect with

    // Construct a SimpleUser instance
    const simpleUser = new Web.SimpleUser(sipClientConfig.socketServer, options);

    // Supply delegate to handle inbound calls (optional)
    simpleUser.delegate = {
      onRegistered: () => {
        // Announce React listeners
        if (events && events.onRegistered) {
          events.onRegistered();
        }
      },
      onCallReceived: async () => {
        // Announce React listeners
        if (events && events.onCallReceived) {
          events.onCallReceived();
        }

        await simpleUser.answer({
          sessionDescriptionHandlerOptions: {
            constraints: { audio: true, video: false },
          },
        });
      },
      onCallAnswered() {
        // Announce React listeners
        if (events && events.onCallAnswered) {
          events.onCallAnswered();
        }
      },
      onCallHangup() {
        // Announce React listeners
        if (events && events.onCallHangup) {
          events.onCallHangup();
        }
      },
      onServerConnect() {
        // Announce React listeners
        if (events && events.onServerConnect) {
          events.onServerConnect();
        }
      },
      onServerDisconnect(error = null) {
        // Announce React listeners
        if (events && events.onServerDisconnect) {
          events.onServerDisconnect(error);
        }
      },
    };

    // Connect to server
    await simpleUser.connect();

    // Register to receive inbound calls (optional)
    await simpleUser.register();

    return simpleUser;
  },
};

export default SipClient;
