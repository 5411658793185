import React from 'react';
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps";

class LocationMap extends React.PureComponent {

    constructor(props) {
        super(props);

        this.GOOGLE_MAPS_API_KEY = 'AIzaSyAStzMkvPGDv8BAeK32mYCxMDJ422AAwJ0';
    }

    render() {
        const {props} = this;

        const {
            lat,
            lng,
        } = props;

        const MapWithAMarker = withScriptjs(withGoogleMap(props =>
            <GoogleMap
                defaultZoom={8}
                defaultCenter={{lat: lat, lng: lng}}
            >
                <Marker
                    position={{lat: lat, lng: lng}}
                />
            </GoogleMap>
        ));

        return (
            <div>
                { /*<h5>Map</h5>*/ }

                <div>
                    <MapWithAMarker
                        googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + this.GOOGLE_MAPS_API_KEY + "&v=3.exp"}
                        loadingElement={<div style={{height: `100%`}}/>}
                        containerElement={<div style={{height: `250px`}}/>}
                        mapElement={<div style={{height: `100%`}}/>}
                    />
                </div>
            </div>
        )
    }
}

export default LocationMap;
