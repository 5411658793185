import React, { useState, useEffect } from "react";

const Stopwatch = () => {

    const [time, setTime] = useState(0);
    
    useEffect(() => {

      const timer = setTimeout(() => {
        setTime((prevTime) => prevTime + 1000);
      }, 1000);

      return () => clearTimeout(timer);

    });

    return (
      <div className="text-white-stopwatch">
        <span>{("0" + Math.floor((time / 3600000) % 60)).slice(-2)}:</span>
        <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
        <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
      </div>
    );

  };

  export default Stopwatch;
