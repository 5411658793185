import ApiClient from './../../common/lib/ApiClient';

var EventEmitter = require('events').EventEmitter;
var emitter = new EventEmitter();

var _logs = [];

export default {
    getLogs: function() {
        return _logs.concat();
    },

    subscribe: function(callback) {
        emitter.on('update', callback);
    },

    unsubscribe: function(callback) {
        emitter.removeListener('update', callback);
    },

    setData: function ( data ) {
        _logs = data;
        emitter.emit('update');
    },

    loadFromServer: function () {
        ApiClient.get({
            endpoint: '/api/v1/debuglog'

        }).then(res => {
            _logs = res.body.data;
            emitter.emit('update');

        }, (err) => {

        })

    }
};
