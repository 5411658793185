import React from 'react';

import ApiClient from '../../../common/lib/ApiClient';
import {WEB_LISTENER_API_BASE_PATH, WEB_LISTENER_URL_BASE_PATH} from '../../../common/lib/constants';

export default class LoginForm extends React.Component {

    constructor(props) {
        super(props)

        this.submit = this.submit.bind(this)
        this.updateUsername = this.updateUsername.bind(this)
        this.updatePassword = this.updatePassword.bind(this)

        this.state = {
            loading: false,
            username: '',
            password: '',
            error: false,
            errorMsg: null,
            redirectToDashboard: false
        }
    }

    submit(ev) {
        ev.preventDefault();

        this.setState({
            loading: true
        })

        ApiClient.post({
            endpoint: `${WEB_LISTENER_API_BASE_PATH}/auth/login`,
            data: this.state
        }).then((res) => {
            this.props.onLogin( res.body.data.jwt, () => {

                this.setState({
                    error: false,
                    errorMsg: false,
                    loading: false,
                    username: '',
                    password: '',
                    redirectToDashboard: true
                })
            } )

        }, (err) => {
            this.setState({
                loading: false,
                error: true,
                errorMsg: err.response && err.response.body && err.response.body.error ? err.response.body.error : 'API communication error'
            })
        } );
    }

    updateUsername(ev) {
        this.setState({ username: ev.target.value });
    }

    updatePassword(ev) {
        this.setState({ password: ev.target.value });
    }

    render() {

        const { error, errorMsg, loading } = this.state;
        const { redirectToDashboard } = this.state

        if ( redirectToDashboard ) {
            console.log('will redirect to dashboard')
            window.location = `${WEB_LISTENER_URL_BASE_PATH}/`
            return null;
        }

        return (

            <form onSubmit={this.submit} className="form-horizontal">
                <fieldset className="pt-3">
                    {loading ? <div className={'blockingOverlay'}><div className={'loader'}><i className="fa fa-spin fa-spinner"/></div></div> : null }
                    {!error ? null :
                    <div className="text-danger text-center form-error innerB">
                        {errorMsg}
                    </div>
                    }
                    <div className="form-group">
                        <div className="">
                            <input type="text" className="form-control " id="inputUsername"
                                   onChange={this.updateUsername}
                                   placeholder="Listener username"
                                   autoComplete="off" />
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="">
                            <input type="password" className="form-control " id="inputPassword"
                                   onChange={this.updatePassword}
                                   placeholder="Listener password"
                                   autoComplete="off" />
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="text-center mt-4">
                            <button type="submit" className="btn btn-orange btn-block login-submit">Login</button>
                        </div>
                    </div>
                </fieldset>
            </form>
        );
    }
}
