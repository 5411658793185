import React from 'react';
import UserStore from './../../common/stores/User';
import Settings from './../../common/stores/Settings';
import cx from 'classnames';

import LoginForm from './form';
import Col from "react-bootstrap/Col";


export default class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.onLogin = this.onLogin.bind(this)
  }

  onLogin (token, callback) {
      UserStore.loginUser(token, callback);
  }

  render() {
      const props = this.props;

      const config = Settings.getConfig();
    return (
        <div className={cx({'loginContainer': true, 'container': true})}>
          <div className="page-header">
            <h1 className="login-logo text-center border-bottom innerB half">
                {!config.site_logo ? null :
                    <img src={config.site_logo} title={config.site_name} className="img-responsive"/>
                }
                <span>{config.site_name}</span>
            </h1>
          </div>

          <Col sm={{span: 8, offset: 2}}>
              <LoginForm onLogin={this.onLogin} {...props} />
          </Col>

          <div className="page-footer">
            
            {/*<div>
              &copy; {new Date().getFullYear()} All Rights Reserved
            </div>
            <div className="footer-link">
              <a href="https://www.sptk.com/" target="_blank" rel="noopener">
                www.sptk.com
              </a>
              </div>*/}
          </div>
        </div>
    );
  }
}
