import React from "react";
import { Redirect } from 'react-router-dom';
import lodash from 'lodash';

import ApiClient from './../../common/lib/ApiClient';
import UserStore from './../../common/stores/User';
import UsersStore from './../../common/stores/Users'

import PageLoading from './../../common/components/PageLoading';
import PageHeading from './../../common/components/PageHeading';
import Notifications from '../../common/lib/Notifications';

import FormField from "../../common/components/FormField";
import PasswordField from "../../common/components/PasswordField";

class ManageListener extends React.Component {

    constructor(props) {
        super(props)

        this._handleChange          = this._handleChange.bind(this)
        this._handleChangeUsername  = this._handleChangeUsername.bind(this)
        this._handleChangeAttribute = this._handleChangeAttribute.bind(this)
        this._handleFormSubmit      = this._handleFormSubmit.bind(this)
        this.postUsersStoreUpdate   = this.postUsersStoreUpdate.bind(this)

        this.state = {
            id: props.match && props.match.params && props.match.params.id ? props.match.params.id : null,
            dataChanged: false,
            users: null,
            passwordConfirmation: '',
            data: {
                is_active: 1
            }
        }
    }

    loadData(userId) {
        if ( !userId ) {
            return false;
        }

        ApiClient.get({
            endpoint: '/api/v1/subscription/' + userId,
            query: {
                include: 'user,attributes'
            }

        }).then(res => {
            this.setState({
                dataChanged: false,
                data: res.body.data,
                passwordConfirmation: '',
                id: res.body.data.id
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    _handleFormSubmit (event) {
        if ( event ) { // real form submission
            event.preventDefault();
        }

        // Let's check if the two passwords match
        if ( !this.state.id ) {
            if ( !this.state.data.sip_password ) {
                const error = 'The password is required when creating an account';
                Notifications.showNotific8(error, 'danger', { closeInSeconds: 6 } );
                return false;
            }
        }

        if ( this.state.data.sip_password && this.state.data.sip_password !== this.state.data.sip_password_confirm ) {
            const error = 'The two passwords do not match';
            Notifications.showNotific8(error, 'danger', { closeInSeconds: 6 } );
            return false;
        }

        this.setState({
            savingForm: true
        });

        let { data } = this.state;
        data.account_type = 'listener';

        // What kind of submit?
        if ( this.state.id ) {

            ApiClient.put({
                endpoint: '/api/v1/subscription/' + this.state.id,
                query: { include: 'user,attributes' },
                data: data
            }).then((res) => {

                // Only if it's success call we alter the data
                var data = res && res.body && res.body.data ? res.body.data : null;

                Notifications.showNotific8(
                    'The account data has been saved',
                    'success', {
                        closeInSeconds: 6
                    });

                this.setState({
                    data: data,
                    passwordConfirmation: '',
                    savingForm: false,
                    fireRedirect: true
                })

            }, (err) => {
                ApiClient.onError(err)
            });

        } else {

            const {newPackage} = this.state;
            data.newPackage = newPackage;

            ApiClient.post({
                endpoint: '/api/v1/subscription',
                query: { include: 'user' },
                data: data
            }).then((res) => {

                // Only if it's success call we alter the data
                var data = res && res.body && res.body.data ? res.body.data : null;

                Notifications.showNotific8(
                    'The account has been added to the system',
                    'success', {
                        closeInSeconds: 6
                    });

                this.setState({
                    passwordConfirmation: '',
                    savingForm: false,
                    fireRedirect: true
                }, function() {
                    // Show success

                    // Let parent know about the change
                    if ( this.props.onUserDataChanged ) {
                        this.props.onUserDataChanged( this.state.data );
                    }
                })

            }, (err) => {
                ApiClient.onError(err)
            });
        }
    }

    _handleChangeUsername(event) {
        let value = {};
        value[event.target.name] = event.target.value;
        value[event.target.name] = value[event.target.name].toLowerCase();

        let {data} = this.state;
        if ( !data ) {
            data = {};
        }
        lodash.assign ( data, value )

        this.setState ( { data: data, dataChanged: true }  );
    }

    _handleChangeField = field => state => {
        let { data, errors } = this.state;

        let value = {};
        value[field] = state.value && state.value;

        if ( !data ) {
            data = {};
        }

        if (!errors) { errors = {} }
        if (state.errors && state.errors.length) {
            errors[field] = state.errors[0]; // Get first error
        } else {
            delete errors[field]
        }

        lodash.assign ( data, value );

        this.setState ( { data, errors, dataChanged: true }  );
    }

    _handleChange(event) {
        let value = {};
        value[event.target.name] = event.target.value;

        let {data} = this.state;
        if ( !data ) {
            data = {};
        }
        lodash.assign ( data, value )

        this.setState ( { data: data, dataChanged: true }  );
    }

    _handleChangeAttribute(event) {
        const {target} = event;

        const {data} = this.state;
        if ( !data.attributes ) {
            data.attributes = {};
        }
        if ( !data.attributes.data ) {
            data.attributes.data = [];
        }
        const attributeIdx = lodash.findIndex(data.attributes.data, { 'field_name': target.name });

        if ( -1 !== attributeIdx ) {
            data.attributes.data[attributeIdx].value = target.value;
        } else {
            // We need to create this attribute
            if ( !data.attributes.data ) {
                data.attributes.data = [];
            }

            data.attributes.data.push({
                value: target.value,
                field_name: target.name
            })
        }

        this.setState({
            data: data,
            dataChanged: true
        })
    }

    postUsersStoreUpdate() {
        const users = UsersStore.getUsers();
        this.setState({ users: users })
    }

    componentDidMount() {
        let {match} = this.props;

        if ( match && match.params && match.params.id ) {
            this.loadData( match.params.id )
        }

        const {user_role} = UserStore.getUser();

        if ( 'super-admin' === user_role ) {
            // Subscribe to update events
            UsersStore.subscribe( this.postUsersStoreUpdate );

            // Load data
            UsersStore.loadFromServer();
        }
    }


    componentWillUnmount() {
        const {user_role} = UserStore.getUser();

        if ( 'super-admin' === user_role ) {
            UsersStore.unsubscribe(this.postUsersStoreUpdate);
        }
    }

    render() {

        let {id, data, dataChanged, errors, users} = this.state;
        const { history } = this.props;
        const { fireRedirect } = this.state;

        const user = UserStore.getUser();
        const {user_role} = user;

        const handleChangeImei = this._handleChangeField('device_imei');
        const handleChangeUsername = this._handleChangeField('sip_username');
        const handleChangePassword = this._handleChangeField('sip_password');
        const handleChangeConfirmation = this._handleChangeField('sip_password_confirm');

        const validatePasswordConfirmation = value => {
            const password = this.state.data && this.state.data.sip_password;
            if (value !== password) {
                throw new Error('The passwords do not match')
            }
        };

        return (
            <div className="content">
                <PageHeading title={id ? 'Manage listener' : 'Add listener'}
                        onSave={this._handleFormSubmit}
                             history={history}
                             formError={lodash.size(errors)}
                             dataChanged={dataChanged}
                             cancelGoToUrl="/listeners"
                />

                <div className="row">
                    <div className="col-lg-12">
                        <div className="innerAll bs-component">
                            <div className="" onSubmit={() => {return false;}}>

                            {id && typeof data.id == 'undefined' ? <PageLoading /> :

                                <div className="row">
                                    <div className="col-lg-6 col-xl-4">
                                        <fieldset>
                                            {user_role !== 'super-admin' ?
                                                null
                                                :
                                                <div className="form-group pb-2">
                                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                                        <label htmlFor="user_id" className="control-label">
                                                            Client
                                                        </label>
                                                    </div>

                                                    {users === null ? <i className="fa fa-spin fa-spinner"/> :
                                                        <select className="form-control input-medium"
                                                                name="user_id"
                                                                value={this.state.data.user_id}
                                                                onChange={this._handleChange}>
                                                            <option value={''}>- choose -
                                                            </option>
                                                            {users.map((d) => <option key={'dou' + d.id}
                                                                                      value={d.id}>{d.username}</option>)}
                                                        </select>
                                                    }


                                                </div>
                                            }

                                            <FormField type="text"
                                                       className=""
                                                       fieldId="sip_username"
                                                       label="Username"
                                                       placeholder="enter username"
                                                       defaultValue={this.state.data.sip_username}
                                                       onStateChanged={handleChangeUsername}
                                                       formatter={(value) => value.toLowerCase()}
                                                       hint={this.state.id ? null : "Only lowercase username accepted"}
                                                       disabled={!!this.state.data.id}
                                                       required
                                            />

                                            <PasswordField
                                                className=""
                                                fieldId="sip_password"
                                                label="Password"
                                                placeholder="enter password"
                                                required={true}
                                                thresholdLength={8}
                                                minStrength={1}
                                                onStateChanged={handleChangePassword}
                                            />

                                            <FormField type="password"
                                                       fieldId="sip_password_confirm"
                                                       label="Password confirmation"
                                                       placeholder="enter again the password"
                                                       disabled={!this.state.data.sip_password}
                                                       validator={validatePasswordConfirmation}
                                                       onStateChanged={handleChangeConfirmation}
                                                       required />


                                            {/*<FormField type="text"
                                                       className=""
                                                       fieldId="device_imei"
                                                       label="Device IMEI"
                                                       defaultValue={this.state.data.device_imei || ''}
                                                       onStateChanged={handleChangeImei}
                                            />*/}

                                            {!this.state.data.id ? null :
                                                <FormField type="text"
                                                           className="input-small"
                                                           fieldId="software_version"
                                                           label="Software Version"
                                                           autoComplete="off"
                                                           defaultValue={this.state.data.software_version || ''}
                                                           disabled={true}
                                                />
                                            }

                                        </fieldset>
                                    </div>
                                </div>
                            }
                            </div>

                            {fireRedirect && (
                                <Redirect to={'/listeners'}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManageListener
