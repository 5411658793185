import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import _ from 'lodash';

class IconHoverTooltip extends React.Component {

  render() {

    const {
      labelText,
      iconClass,
      placement,
    } = this.props;

    return (
      <OverlayTrigger
        placement={placement ? placement : "bottom"}
        overlay={(props) => {
          return (<Tooltip {..._.omit(props, ['show'])}>{labelText}</Tooltip>)
        }}
      >
        <i className={iconClass}/>
      </OverlayTrigger>
    )
  }
}

export default IconHoverTooltip;
