import Badge from 'react-bootstrap/Badge';
import _ from 'lodash';
import Table from 'react-bootstrap/Table';
import {secondsToDuration} from '../../common/lib/helper';
import classnames from 'classnames';
import React from 'react';
import IconHoverTooltip from '../../common/components/IconHoverTooltip';

const RecordingParticipants = (props) => {
  const {
    data
  } = props;

  const getAsList = () =>  {

    if (!data.participants || !data.participants.users || !data.participants.users.length) {
      return <p>No data</p>;
    }

    return data.participants.users.map((user,idx) =>
      (
        <Badge className="size14 text-weight-400 mr-3" variant="info" key={'ulist' + data.id + user.display_name + idx}>
          <i className="fal fa-headphones"/> &nbsp;{user.display_name}
        </Badge>
      )
    )}

  const getAsTable = () => {

    if (!data.participants || !data.participants.history || !data.participants.history.length) {
      return null;
    }

    let {
      history
    } = data.participants;

    history = _.orderBy(history, ['ts'], ['asc'])

    return (
      <Table className="participants-table" responsive>
        <tbody>
        <tr>
          <td style={{width: 40}}>
            <span className="recording-duration">{secondsToDuration(0)}</span>
          </td>
          <td className="col-icon">
            <IconHoverTooltip labelText="Recording started"
                              placement="right"
                              iconClass="fal fa-microphone text-danger" />
          </td>
          <td><em>Recording started</em></td>
        </tr>
        {history.map((history,idx) => {

          let labelText = '';
          if ('reconnecting' === history.event) {
            labelText = 'Listener reconnecting'
          }
          if ('connected' === history.event) {
            labelText = 'Listener connected'
          }
          if ('disconnected' === history.event) {
            labelText = 'Listener disconnected'
          }

          return (
            <tr key={'hist' + data.id + history.display_name + idx}>
              <td>
                                <span className="recording-duration">
                                  {secondsToDuration(history.ts - data.recorded_at)}
                                  {/*secondsToDuration(history.ts - data.recorded_at < data.duration ? history.ts - data.recorded_at : data.duration)*/}
                                </span>
              </td>
              <td className="col-icon">
                <IconHoverTooltip labelText={labelText}
                                  placement="right"
                                  iconClass={classnames({
                                    "fal fa-wifi": history.event === 'reconnecting',
                                    "fal fa-user-plus text-success": history.event === 'connected',
                                    "fal fa-user-minus text-warning": history.event === 'disconnected',
                                  })} />
              </td>
              <td>{history.display_name}</td>
            </tr>
          )
        })}
        {/*
        <tr>
          <td>
            <span className="recording-duration">{secondsToDuration(data.duration)}</span>
          </td>
          <td className="col-icon">
            <IconHoverTooltip labelText="Recording ended"
                              placement="right"
                              iconClass="fal fa-microphone-slash text-danger" />
          </td>
          <td><em>Recording ended</em></td>
        </tr>
        */}
        </tbody>
      </Table>
    )
  }

  return (
    <div>
      {/*<h5>Participants</h5>*/}
      <div className="pt-3 pb-3">{getAsList()}</div>
      {getAsTable()}
    </div>
  )
}

export default RecordingParticipants;
