
import React from "react";

// Imports from the charts library
import {RadialBarChart, RadialBar, Legend, ResponsiveContainer,Tooltip} from 'recharts'


const colors = require("./../../common/lib/colors.json");


const style = {
    top: 0,
    left: 350,
    lineHeight: '24px'
};

class CustomTooltip extends React.Component {
    render() {
        const { active } = this.props;

        if (active) {
            const { payload, label } = this.props;
            return (
                <div className="recharts-default-tooltip">
                    <p className="recharts-tooltip-label" style={{marginBottom: '0', color: '#333'}}>{`${payload[0].payload.name}: ${payload[0].payload.value}`}</p>
                </div>
            );
        }

        return null;
    }
}

class ActivityRegionRadialBarChart extends React.Component {
    render() {
        const {data} = this.props;
        let chartData = [];

        let i = 0;

        chartData.push({
            name: 'Sent messages',
            value: data.message_sent,
            fill: colors.stats[i++ % colors.stats.length]
        });

        chartData.push({
            name: 'Received messages',
            value: data.message_received,
            fill: colors.stats[i++ % colors.stats.length]
        });

        chartData.push({
            name: 'Sent files',
            value: data.file_sent,
            fill: colors.stats[i++ % colors.stats.length]
        });

        chartData.push({
            name: 'Received files',
            value: data.file_received,
            fill: colors.stats[i++ % colors.stats.length]
        });

        chartData.push({
            name: 'Incoming calls',
            value: data.call_incoming,
            fill: colors.stats[i++ % colors.stats.length]
        });

        chartData.push({
            name: 'Outgoing calls',
            value: data.call_outgoing,
            fill: colors.stats[i++ % colors.stats.length]
        });

        return (
            <div className="col-lg-6" style={{ height: '350px'}}>
                <h3>{data.region}</h3>
                <ResponsiveContainer>
                    <RadialBarChart cx={170} cy={180}
                                    startAngle={180} endAngle={0} barGap={10}
                                    innerRadius={20}
                                    data={chartData}>
                        <RadialBar minAngle={15}
                                   label={{ fill: '#666', position: 'end' }}
                                   background
                                   clockWise={true}
                                   dataKey='value' />

                        <Tooltip  content={<CustomTooltip/>} />
                        <Legend iconSize={12}
                                width={170}
                                height={140}
                                layout='vertical'
                                verticalAlign='middle'
                                wrapperStyle={style}/>
                    </RadialBarChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

// Export example
export default ActivityRegionRadialBarChart;