import React,{Component, PureComponent} from "react";

import _ from "lodash";

import Select from "react-select";
import Listeners from "../../common/stores/Listeners";
import ApiClient from "../../common/lib/ApiClient";
import Subscriptions from "../../common/stores/Subscriptions";
import Spinner from "react-bootstrap/Spinner";

class AllowedListeners extends Component {

    constructor(props) {
        super(props);

        this.loadSubscription = this.loadSubscription.bind(this)

        this.state = {
            listeners: null,
            allowedListeners: [],
        }
    }

    loadSubscription() {
        const {
            subscription
        } = this.props;

        ApiClient.get({
            endpoint: '/api/v1/subscription/' + subscription.id,
            query: {
                include: 'user,attributes'
            }

        }).then(res => {
            this.setState({
                allowedListeners: res.body.data.allowed_listeners,
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    componentDidMount() {
        ApiClient.get({
            endpoint: '/api/v1/listener?l=10000'

        }).then(res => {
            this.setState({listeners: res.body.data});
        }, (err) => {

        })

        this.loadSubscription()

    }

    render() {
        const {
            subscription,
            onChange,
        } = this.props;

        const {
            listeners,
            allowedListeners,
        } = this.state;

        // Was the data loaded?
        if (listeners === null) {
            return (
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            )
        }

        let options = listeners.map((o) => {
            return {
                label: o.sip_username,
                value: o.id,
            }
        });

        options = _.filter(options, (o) => -1 === _.findIndex(allowedListeners, {id: o.value}))


        return (
            <div>
                <div>
                    <p>Search for a listener by username in order to add it to the allowed listeners list</p>

                    <div className="marginB">
                        <Select
                            options={options}
                            blurInputOnSelect={true}
                            closeMenuOnSelect={true}
                            controlShouldRenderValue={false}
                            isClearable={false}
                            onChange={(value) => {
                                Subscriptions.addListener(subscription.id, value.value, this.loadSubscription)
                            }}
                            pageSize={7}
                            placeholder=""
                        />
                    </div>
                </div>

                <table className="table table-allowed-listeners">
                    <thead>
                    <tr>
                        <th scope="col">Listener</th>

                        <th scope="col">&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {!allowedListeners || !allowedListeners.length ?
                        <tr>
                            <td colSpan={2}>No listeners added</td>
                        </tr>
                        :
                        allowedListeners.map((listener) => {
                            return (
                                <tr key={`alr-${listener.id}`}>
                                    <th scope="row">{listener.sip_username}</th>
                                    <td align="right">
                                        <a href="javascript:;" onClick={() => {
                                            Subscriptions.removeListener(subscription.id, listener.id, this.loadSubscription)

                                        }} className="text-danger">Remove</a>
                                    </td>
                                </tr>)
                        })
                    }

                    </tbody>
                </table>
            </div>
        )
    }
}

export default AllowedListeners;