import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";

class FormField extends Component {

    // initialize state
    state = { value: this.props.defaultValue ? this.props.defaultValue : '', dirty: false, errors: [] }

    hasChanged = e => {
        e.preventDefault();

        // destructure props - assign default dummy functions to validator and onStateChanged props
        const { label, required = false, formatter = f => f, validator = f => f, onStateChanged = f => f } = this.props;

        let value = e.target.value;
        const isEmpty = value.length === 0;
        const requiredMissing = this.state.dirty && required && isEmpty;

        let errors = [];

        if (requiredMissing) {
            // if required and is empty, add required error to state
            errors = [ ...errors, `${label} is required` ];
        } else if ('function' === typeof validator) {
            try {
                validator(value);
            } catch (e) {
                // if validator throws error, add validation error to state
                errors = [ ...errors, e.message ];
            }
        }

        // Apply custom formatting
        if ('function' === typeof formatter) {
            value = formatter(value)
        }

        // update state and call the onStateChanged callback fn after the update
        // dirty is only changed to true and remains true on and after the first state update
        this.setState(({ dirty = false }) => ({ value, errors, dirty: !dirty || dirty }), () => onStateChanged(this.state));
    }

    render() {
        const { value, dirty, errors } = this.state;
        const { autoComplete, type, label, fieldId, placeholder, children, disabled, className, hint, required } = this.props;

        const hasErrors = errors.length > 0;
        const controlClass = ['form-control', className, dirty ? hasErrors ? 'is-invalid' : 'is-valid' : '',  ].join(' ').trim();

        return (
            <Fragment>
                <div className="form-group pb-2">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <label htmlFor={fieldId} className="control-label">
                            {label}
                            {!required ? null : <span className="help-block  marginL half">*</span>}
                        </label>
                        {/** Render the first error if there are any errors **/}
                        { hasErrors && <div className="error form-hint font-weight-bold text-right m-0 mb-2">{ errors[0] }</div> }
                    </div>

                    {!hint ? null :
                        <span className="d-block form-hint">{hint}</span>
                    }

                    {children}
                    <input type={type}
                           className={controlClass} id={fieldId} placeholder={placeholder}
                           autoComplete={autoComplete ? autoComplete : "on"}
                            disabled={disabled}
                           value={value ? value : ''} onChange={this.hasChanged} />


                </div>
            </Fragment>
        );
    }

}

FormField.propTypes = {
    type: PropTypes.oneOf(["text", "password"]).isRequired,
    label: PropTypes.string.isRequired,
    fieldId: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    children: PropTypes.node,
    validator: PropTypes.func,
    onStateChanged: PropTypes.func,
    formatter: PropTypes.func,
    hint: PropTypes.string,
    disabled: PropTypes.bool,
    autoComplete: PropTypes.string,
    className: PropTypes.string,
};

export default FormField