import React from "react";
import { Checkbox, Switch } from 'pretty-checkbox-react';
import 'pretty-checkbox';
import ApiClient from "../../common/lib/ApiClient";

class MonitorOptions extends React.Component {

    constructor(props) {
        super(props);

        const { subscriptionId, active, calls, messages, files, save, twilio, asterisk } = this.props;

        //console.log(props);

        this.state = {
            subscriptionId: subscriptionId,
            active: active,
            calls: calls,
            messages: messages,
            files: files,
            save: save,
            twilio: twilio,
            asterisk: asterisk,
        };

        this.checksum = this.getChecksum(this.state);

        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
        //console.log("props -> %o", props);
        //console.log("state %o", this.state);
    }

    getChecksum(state) {
        delete state.save;
        return this.hashCode(JSON.stringify(state));
    }

    hashCode(str) {
        const val =  str.split('').reduce((prevHash, currVal) =>
            (((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0);

        return val;
    }

    handleSubmit(evt) {
        evt.preventDefault();
        //console.log("----> %s", evt.target.id);
        //console.log(" checksum: %d", this.checksum);

        //Commit the changes to the state
        ApiClient.post({
            endpoint: '/api/v1/monitor/' + this.state.subscriptionId,
            data: this.state,
        }).then(res => {
            let result = { save: false };
            this.checksum = this.getChecksum(this.state);
            this.setState(result);
        }, (err) => {
            ApiClient.onError(err);
        });


    }

    onChange(evt) {
        console.log("----> %s : %s : %s", evt.target.name, evt.target.value, evt.target.checked);

        //console.log(" checksum: %d", this.checksum);
        //console.log(this);

        let result = this.handleMonitorAction(evt.target.name, evt.target.value, evt.target.checked);

        //console.log(" --> setState -> %o", result);

        /*
            - Auto Commit if disabled (all properties), don't show warning or save button
            - Check if save + warning should be shown + show button
            - Set the new checksum regardless
         */
        const prevChecksum = this.checksum;
        const newState = {...this.state, ...result };
        const newChecksum = this.getChecksum(newState);

        let showSave = false;

        if(prevChecksum !== newChecksum) {
            showSave = true;
        }

        result = { ...result, save: showSave };

        //this.checkSum = newChecksum;

        this.setState(result);
    }

    handleMonitorAction(key, value, checked) {

        /*
            find the key in the state
            if the key exists, set the state value based on the type of property
            and the element that was activated
         */

        let result = {};

        if(this.state.hasOwnProperty(key)) {
            if(value) {
                value = value.trim();
            }

            if(typeof this.state[key] === "boolean") {
                result[key] = checked;
            } else if(typeof this.state[key] === "string") {
                result[key] = (!value ? null : value);
            } else if(this.state[key] === null) {
                result[key] = (!value ? null : value);
            }
        }

        if(!result.hasOwnProperty(key)) {
            result = this.state;
        }

        return result;
    }

    render() {

        //console.log("render state = %o", this.state);

        const saveStateDiv = (
            <div className="monitor-save-state">
                <div className="monitor-unsaved">* Unsaved changes</div>
                <div className="monitor-save-button">
                    <button form={'monitor-form' + this.state.subscriptionId } type="submit" className="btn btn-warning inline-block">Save</button>
                </div>
            </div>
        );

        const noteDiv = (
            <div className="monitor-note"><b>Note:</b>&nbsp;Call out priority is <b><i>Asterisk</i></b>, then falling back to <b><i>Twilio</i></b></div>
            );

        const monitorOptionsDiv = (
            <>
                <i className="fal fa-arrow-right fa-lg marginR"></i>

                <Checkbox 
                    name="calls"
                    color="success"
                    state={ this.state.calls }
                    setState={ this.setState }
                    onChange={ this.onChange }
                    >
                    Calls
                </Checkbox>
                
                <Checkbox 
                    name="messages"
                    color="success"
                    state={ this.state.messages }
                    setState={ this.setState }
                    onChange={ this.onChange }
                    >
                    Messages
                </Checkbox>

                <Checkbox 
                    name="files"
                    color="success"
                    state={ this.state.files }
                    setState={ this.setState }
                    onChange={ this.onChange }
                    >
                    Files
                </Checkbox>

                <div className="monitor-inputs">
                    {/* <label htmlFor="asterisk">Asterisk&nbsp;</label>
                    <input name="asterisk" placeholder="user@asterisk_config_name" onChange={ this.onChange } value={ this.state.asterisk || '' }/> */}
                    {/* <label htmlFor="twilio">Twilio&nbsp;</label>
                    <input name="twilio" placeholder="+#no1, +#no2, +..." onChange={ this.onChange } value={ this.state.twilio }/> */}
                </div>

                { /*this.state.asterisk !== null && this.state.twilio !== null && noteDiv */}
            </>
        );

        return (
            <>
                <form id={ 'monitor-form' + this.state.subscriptionId }
                      className={ 'monitor-form-' + (this.state.active? 'enabled' : 'disabled') }
                      onSubmit={ this.handleSubmit }>
                    <Switch
                        /*style={ { fontSize: 20 }}*/
                        name="active"
                        color="primary"
                        state={ this.state.active }
                        setState={ this.setState }
                        onChange={ this.onChange }
                        >
                        { this.state.active ? "Enabled" : "Disabled" }
                    </Switch>
                    { this.state.active && monitorOptionsDiv }
                </form>
                { this.state.save && saveStateDiv }
            </>
        );
    }
}

export default MonitorOptions;