import ApiClient from './../../common/lib/ApiClient';

var EventEmitter = require('events').EventEmitter;
var emitter = new EventEmitter();

var _data = [];

export default {
    getData: function() {
        return _data.concat();
    },

    subscribe: function(callback) {
        emitter.on('update', callback);
    },

    unsubscribe: function(callback) {
        emitter.removeListener('update', callback);
    },

    setData: function ( data ) {
        _data = data;
        emitter.emit('update');
    },

    loadFromServer: function (options) {
        if (!options) {
            options = {};
        }
        const rOptions = options;

        rOptions.endpoint = '/api/v1/storage/recordings';

        return ApiClient.get(rOptions).then(res => {
            _data = res.body.data;
            emitter.emit('update');

            return _data;
        }, (err) => {

        })

    },

    getDownloadUrl: function (recordingId) {

        return ApiClient.get({
            endpoint: '/api/v1/storage/recordings/' + recordingId + '/prepare-download'

        }).then(res => {
            _data = res.body.data;

            return _data;
        }, (err) => {

        })
    },

    update(id, data) {

        return ApiClient.patch({
            endpoint: '/api/v1/storage/recordings/' + id,
            data: data
        });
    },

    updatePcm(id, data) {

        return ApiClient.post({
            endpoint: '/api/v1/recording/' + id + '/pcm',
            data: data
        });
    },

    removeRegion(id, region_id) {
        return ApiClient.delete({
            endpoint: '/api/v1/recording/' + id + '/region/' + region_id
        });
    },

    updateRegion(id, region_id, data) {
        return ApiClient.post({
            endpoint: '/api/v1/recording/' + id + '/region/' + region_id,
            data: data
        });
    },
};
