import React from "react";

export default ({ filter, onChange, column }) => (
    <input
        autoComplete="off"
        autoCorrect="off"
        spellCheck="off"
        type="text"
        style={{
            width: '100%',
        }}
        placeholder={column.Placeholder}
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
    />
)