import React from 'react'
import { Redirect } from 'react-router-dom'
import ListenerUserStore from './../../common/stores/ListenerUser'
import {WEB_LISTENER_URL_BASE_PATH} from '../../common/lib/constants';

class LogoutPage extends React.Component {

    componentWillMount() {
        ListenerUserStore.logoutUser();
    }

    render() {
        return (
            <Redirect to={`${WEB_LISTENER_URL_BASE_PATH}/login`} />
        )
    }
}

export default LogoutPage
