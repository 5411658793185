import React from 'react';
import DatetimeRangePicker from './../../common/components/bootstrap-datetimerangepicker';
import moment from 'moment';

import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

class DateRangePicker extends React.Component {

    constructor(props) {
        super(props);

        this.handleApply = this.handleApply.bind(this);

        this.state = {
            startDate: typeof props.startDate != "undefined" ? props.startDate : moment().subtract(29, 'days').startOf('day'),
            endDate: typeof props.endDate != "undefined" ? props.endDate : moment().endOf('day'),
            ranges: {
                'Today': [moment().startOf('day'), moment().endOf('day')],
                'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
                'Last Year': [moment().subtract(1, 'year').startOf('day'), moment().endOf('day')],
                'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
                'Last Month': [moment().subtract(1, 'month').startOf('month').startOf('day'), moment().subtract(1, 'month').endOf('month').endOf('day')],
            },
        };
    }

    handleApply(event, picker) {
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,

        }, function () { // Announce listeners to onChange prop
            if ( this.props.onChange ) {
                const {startDate,endDate} = this.state;

                this.props.onChange( startDate,endDate )
            }
        });
    }

    render() {
        const { autoUpdateInput, noTimePicker } = this.props;

        let label = '', start = '', end = '';
        if (this.state.startDate) {
            start = this.state.startDate.format('MMMM D, YYYY HH:mm');
        }
        if (this.state.endDate) {
            end = this.state.endDate.format('MMMM D, YYYY HH:mm');
        }

        if (start && end) {
            label = start + ' - ' + end;
            if (start === end) {
                label = start;
            }
        }

        return (
            <div className="daterange-picker">
                <div className="bg-white ">
                    <DatetimeRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onApply={this.handleApply}
                        timePicker={!noTimePicker}
                        timePicker24Hour
                        timePickerIncrement={30}
                        showDropdowns
                        ranges={this.props.ranges ? this.props.ranges : this.state.ranges}
                        maxSpan={this.props.maxSpan ? this.props.maxSpan : { days: 90 }}
                    >

                        <InputGroup>
                            <FormControl type="text"
                                         className="form-control border-right-none"
                                         onChange={() => {}}
                                         value={label} />
                            <InputGroup.Append>
                                <InputGroup.Text className="bg-white">
                                    <i className="fa fa-angle-down"/>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>

                    </DatetimeRangePicker>
                </div>
            </div>
        );
    }

}

export default DateRangePicker;