
import React from 'react'
import {
    Route,
    Link,
    Redirect,
    withRouter
} from 'react-router-dom'


import UserStore from './../stores/User';

const AdminAreaRouter = ({ component: Component, ...rest }) => {
    let props = rest;

    let element = Component ? (<Component {...props}/>) : rest.children;
    return UserStore.isAdmin() ? element : null
}

export default withRouter(AdminAreaRouter);
