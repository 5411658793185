import React from 'react';

import _ from 'lodash';

import ApiClient from "../../common/lib/ApiClient";
import SettingsStore from "../../common/stores/Settings";
import humanize from 'humanize-duration';

import ReactTable from 'react-table';
import CustomPaginationComponent from './../../common/lib/ReactTable-Pagination';
import UserStore from "../../common/stores/User";

const colors = require("./../../common/lib/colors.json");

class ActiveUsers extends React.Component {
    state = {
        loaded: false,
        timerangeFilter: null,
        regionFilter: null,
        accountFilter: null,
        regions: null,
        data: []
    }

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        document.title = "Reports - Active Users | " + SettingsStore.getConfig().site_name;

        this.fetchData();
    }

    fetchData() {
        this.setState({loading: true})

        ApiClient.get({
            endpoint: '/api/v1/analytics/active-users'

        }).then(res => {

            this._dataList = res.body.data;

            this.setState({
                data: res.body.data,
                loaded: true,
                loading: false
            })

            window.setTimeout( this.fetchData.bind(this), 30000 )

        }, (err) => {
            ApiClient.onError(err)
        })
    }


    render() {
        const {users} = this.state;


        let {timezone} = UserStore.getUser();
        timezone = timezone ? timezone : 'UTC';

        const columns = [

            {
                Header: 'Username',
                accessor: 'real_username',
                sortable: true,
                filterable: true,
                Cell: row => {
                    return (
                        <div>
                            <strong>{row.value}</strong>
                            <div className="last-login help-block">{row.original.jid}</div>
                        </div>
                    )
                }
            },

            {
                Header: 'IP Address',
                accessor: 'ip',
                sortable: false,
                width: 200,
                className: 'text-center',
                headerClassName: 'text-center',
                filterable: true
            },

            {
                Header: 'Uptime',
                accessor: 'uptime',
                sortable: true,
                filterable: false,
                width: 200,
                className: 'text-right',
                headerClassName: 'text-right',
                Cell: row => {
                    const value = humanize(row.value * 1000, { largest: 2 });
                    return (<span>{value}</span>);
                }
            }];


        let dataList = this.state.data;

        return (
            <div>
                <h1>Active Users</h1>

                <div className="content">

                    <div>
                        <ReactTable
                            PaginationComponent={CustomPaginationComponent}
                            showPageSizeOptions={true}
                            pageSizeOptions={[10, 25, 50, 100, 200]}
                            className=""
                            paginationClassName="pagination-filters clearfix"
                            data={dataList}
                            columns={columns}
                            defaultPageSize={10}
                            minRows={1}
                            loading={!this.state.loaded || this.state.loading}
                        />

                    </div>
                </div>
            </div>
        )
    }
}

export default ActiveUsers;