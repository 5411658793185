import React from 'react'
import classnames from 'classnames'
import ReactTablePagination from "./ReactTable-Pagination";


const defaultButton = (props) => (
    <button {...props} className='-btn'>{props.children}</button>
)

class GenericPagination extends React.Component {
    constructor(props) {
        super(props)

        this.applyPage = this.applyPage.bind(this)

        this.state = {
            page: props.page
        }
    }

    getInitialState () {
        return {
            page: this.props.page
        }
    }

    componentWillReceiveProps (nextProps) {
        this.setState({page: nextProps.page})
    }

    getSafePage (page) {
        return Math.min(Math.max(page, 0), this.props.pages - 1)
    }

    changePage (page) {
        page = this.getSafePage(page)
        this.setState({page})
        this.props.onPageChange(page)
    }

    applyPage (e) {
        e && e.preventDefault()
        const page = this.state.page
        this.changePage(page === '' ? this.props.page : page)
    }

    render () {
        const {
            loading,
            totalRowsCount,
            // Computed
            pages,
            // Props
            page,
            showPageSizeOptions,
            pageSizeOptions,
            pageSize,
            onRefreshData,
            showPageJump,
            onPageSizeChange,
            className,
            PreviousComponent = defaultButton,
            NextComponent = defaultButton
        } = this.props

        var canPrevious = page > 0;
        var canNext = page + 1 < pages;


        const refreshCx = classnames({
            'fa-spin': loading,
            'far fa-sync': true
        })

        return (
            <div
                className={classnames({[className]: true, 'clearfix': true})}
                style={this.props.paginationStyle}
            >

                <div className="marginR margin-bottom-10 ">
                    <button className="btn btn-pagination  pull-left "
                            disabled={loading}
                            onClick={onRefreshData}><i className={refreshCx}/></button>
                </div>

                <div className="marginR margin-bottom-10 btn-group">
                    <button className="btn   btn-pagination"
                            disabled={!canPrevious}
                            onClick={(e) => {
                                if (!canPrevious) return
                                this.changePage(page - 1)
                            }}>
                        <i className="fa fa-chevron-left"/>
                    </button>

                    <button className="btn   btn-pagination"
                            onClick={(e) => {
                                if (!canNext) return
                                this.changePage(page + 1)
                            }}
                            disabled={!canNext}>
                        <i className="fa fa-chevron-right"/>
                    </button>
                </div>

                <div className="marginR margin-bottom-10 ">
                    <div className="page-selector-addon">
                        <span className="">
                            {this.props.pageText}
                        </span>
                    </div>

                    <div className="page-selector-input">

                        <form className='-pageJump'
                              onSubmit={this.applyPage}
                        >
                            <input
                                className="page-selector form-control input-sm"
                                type={this.state.page === '' ? 'text' : 'text'}
                                onChange={e => {
                                    const val = e.target.value
                                    const page = val - 1
                                    if (val === '') {
                                        return this.setState({page: val})
                                    }
                                    this.setState({page: this.getSafePage(page)})
                                }}
                                value={this.state.page === '' ? '' : this.state.page + 1}
                                onBlur={this.applyPage}
                            />
                        </form>

                    </div>

                    <div className="page-selector-addon">
                                <span className="">
                                    {this.props.ofText} <span className='-totalPages'>{pages}</span>
                                </span>
                    </div>

                    {showPageSizeOptions && (
                        <>
                        <div className="page-selector-addon marginL">Show</div>
                        <div className="page-selector-input">
                            <span className='select-wrap -pageSizeOptions'>
                                  <select
                                      className="form-control input-sm"
                                      onChange={(e) => onPageSizeChange(Number(e.target.value))}
                                      value={pageSize}
                                  >
                                    {pageSizeOptions.map((option, i) => {
                                        return (
                                            <option
                                                key={i}
                                                value={option}>
                                                {option} {this.props.rowsText}
                                            </option>
                                        )
                                    })}
                                  </select>
                                </span>
                        </div>
                        </>
                    )}
                </div>

                {!totalRowsCount ? null :
                    <div className="marginL text-right" style={{flexGrow: 1}}>
                        {totalRowsCount + (totalRowsCount === 1 ? " row" : " rows") + " found"}
                    </div>
                }
            </div>
        )
    }
}

GenericPagination.defaultProps = {
    previousText: 'Previous',
    nextText: 'Next',
    loadingText: 'Loading...',
    noDataText: 'No rows found',
    pageText: 'Page',
    ofText: 'of',
    rowsText: 'rows',
    pageJumpText: 'jump to page',
    rowsSelectorText: 'rows per page',
}

export default GenericPagination;