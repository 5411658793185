import React from 'react';

import _ from 'lodash';

import Select from 'react-select'

import ApiClient from "../../common/lib/ApiClient";
import SettingsStore from "../../common/stores/Settings";
import moment from 'moment-timezone'

import ReactTable from 'react-table';
import CustomPaginationComponent from './../../common/lib/ReactTable-Pagination';
import UserStore from "../../common/stores/User";
import qs from "qs";
import Button from "react-bootstrap/Button";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import classnames from "classnames";
import DatetimeRangePicker from "../../common/components/bootstrap-datetimerangepicker";
import {formatFileSize} from "../../common/lib/helper";
import CustomTableFilter from "../../common/lib/ReactTable-Filter";


class ApiLogPage extends React.Component {
    state = {
        changesModalData: null,
        loaded: false,
        timerangeFilter: null,
        regionFilter: null,
        accountFilter: null,
        regions: null,
        data: [],

        tableSelfState: {},
    }

    constructor(props) {
        super(props)

        this.fetchData = this.fetchData.bind(this)
    }

    componentDidMount() {
        document.title = "Audit - API access | " + SettingsStore.getConfig().site_name;

        //this.fetchData()
    }

    fetchData(state, instance) {

        this.setState(
            {
                loading: true,
                tableSelfState: state,
            })

        let filters = qs.stringify({f: state.filtered}, { arrayFormat: 'indices', encodeValuesOnly: true });
        let order = qs.stringify({o: state.sorted}, { arrayFormat: 'indices', encodeValuesOnly: true });

        let request = ApiClient.get({
            endpoint: '/api/v1/audit/api-log',
            query: {
                //include: 'user,attributes',
                q: this.state.query,
                p: state.page,
                l: state.pageSize
            }

        });

        if ( filters ) {
            request.query( filters );
        }

        if ( order ) {
            request.query( order );
        }

        request.then(res => {

            this._dataList = res.body.data;

            this.setState({
                data: res.body.data,
                pages: res.body.pages,
                totalResults: res.body.totalResults,
                loaded: true,
                loading: false
            })

        }, (err) => {
            ApiClient.onError(err)
            this.setState({
                loading: false
            })
        })
    }

    showChangesModal(changes) {
        this.setState({
            changesModalData: changes
        })
    }

    render() {
        const { changesModalData, pages, loading, tableSelfState, totalResults } = this.state;

        let {timezone} = UserStore.getUser();
        timezone = timezone ? timezone : 'UTC';

        const columns = [

            {
                Header: 'Resource',
                sortable: false,
                accessor: 'object_identifier',
                className: 'text-left',
                headerClassName: 'text-left',
                minWidth: 270,
                filterable: true,

                Cell: (row) => {
                    let identifier = row && row.value && row.value.toString();
                    if (!identifier) {
                        identifier = '';
                    }

                    let method = identifier.match(/^(GET|POST|PUT|PATCH|DELETE|OPTIONS|HEAD) (.*?)(\?.*)?$/)

                    let path = '/'
                    let query = ''

                    if (method && method[2]) {
                        path = method[2]
                    }

                    if (method && method[3]) {
                        query = method[3]
                    }

                    if (method && method[1]) {
                        method = method[1]
                    } else {
                        method = 'N/A'
                    }

                    const badgeCx = "mr-2 size12 text-weight-normal"

                    let variant = 'light'
                    if (method === 'GET') {
                        variant = 'info'

                    } else if (method === 'PUT' || method === 'POST') {
                        variant = 'warning'

                    } else if (method === 'DELETE') {
                        variant = 'danger'
                    }

                    return (
                        <>
                            <Badge className={badgeCx} variant={variant}>{method}</Badge>
                            <span className="mr-1">{path}</span>
                            <span className="text-muted-dark">{`${query}`}</span>
                        </>
                    )
                }
            },
            {
                Header: 'Request time',
                sortable: true,
                accessor: 'created_at',
                className: 'text-center',
                headerClassName: 'text-center',
                width: 160,
                filterable: true,
                Filter: ({filter, onChange}) => {

                    let startDate = null,
                        endDate = null,
                        label = 'Click to filter';

                    if (filter) {
                        let { value } = filter;

                        if (value) {
                            label = '';
                            value = value.split(':')

                            if (value[0]) {
                                startDate = moment.unix(value[0])
                                label += startDate.format('MMM D, YYYY') + ' - '
                            }
                            if (value[1]) {
                                endDate = moment.unix(value[1])
                                label += endDate.format('MMM D, YYYY');
                            }
                        }
                    }

                    const locale = {
                        cancelLabel: 'Clear filter',
                    }

                    return (
                        <DatetimeRangePicker
                            locale={locale}
                            startDate={startDate}
                            endDate={endDate}
                            onApply={(event, picker) => {
                                const { startDate, endDate } = picker;
                                let newts = ( startDate.unix() ).toString() + ':';
                                if ( endDate ) {
                                    newts = newts + ( endDate.unix() ).toString();
                                }
                                onChange(newts)
                            }}
                            onCancel={(event,picker) => {
                                onChange('')
                            }}
                            timePicker={false}
                            showDropdowns

                        >

                            <div className="rt-filter-label">
                                <i className="fa fa-calendar-alt"/>&nbsp; {label}
                            </div>

                        </DatetimeRangePicker>
                    );
                },
                Cell: row => {
                    if ( !row.value ) {
                        return null;
                    }

                    var c = moment.unix(row.value).tz(timezone);
                    return ( <span>{c.format('HH:mm:ss MMM D, YYYY')}</span> )
                }
            },
            {
                Header: 'API version',
                accessor: 'object_type',
                sortable: true,
                filterable: true,
                className: "text-center",
                width: 120,
            },
            {
                Header: 'Action user',
                accessor: 'user_name',
                sortable: true,
                filterable: true,
                width: 140,
                Cell: (row) => {
                    return (
                        <div>
                            <span className="mr-2">{row.original.user_name}</span>
                            <Badge variant={'admin' === row.original.user_type ? 'dark' : 'light'}>{row.original.user_type}</Badge>
                        </div>
                    )
                }
            },
            {
                Header: 'IP Address',
                accessor: 'ip_address',
                sortable: false,
                maxWidth: 120,
                filterable: true,
            },
            {
                Header: 'Details',
                accessor: 'event_data',
                sortable: false,
                filterable: false,
                minWidth: 220,
                className: "row-actions size12 py-2",
                Cell: ({row}) =>  {
                    if (!row || !row.event_data) {
                        return null;
                    }

                    const hasData = _.size(row.event_data)
                    const eventDataArr = [];

                    if (hasData) {
                        for (let field in row.event_data) {

                            if(row.event_data.hasOwnProperty(field)) {
                                eventDataArr.push({
                                    column: field,
                                    value: row.event_data[field],
                                })
                            }
                        }
                    }

                    return (
                        <>
                        {eventDataArr.map((data) => {


                            // Prepare column
                            const column = data && data.column;
                            const columnNice = _.startCase(column)

                            // Format the data, if needed
                            let value = '';

                            if (data && data.value) {
                                value = data.value.toString();

                                // Unix timestamp?
                                if (value.match(/^[0-9]{10}$/)) {
                                    value = moment.unix(value).format('HH:mm:ss MMM D, YYYY')
                                }

                                // File size?
                                if ('file_size' === column) {
                                    // Format to size
                                    value = formatFileSize(value)
                                }
                            }

                            return (
                                <div key={`${row.id}-cmdae-${column}`}>
                                    <span className="marginR half text-weight-500 d-inline-block" style={{minWidth: 60}}>{columnNice}</span>
                                    <span>{value}</span>
                                </div>
                            )
                        })}
                        </>
                    )
                }
            }
        ];


        let dataList = this.state.data;

        let changesModalDataArr = [];
        if (changesModalData) {
            for (let field in changesModalData) {

                if(changesModalData.hasOwnProperty(field)) {
                    changesModalData[field].column = field;
                    changesModalDataArr.push(changesModalData[field])
                }
            }
        }

        const refreshCx = classnames({
            'fa-spin': loading,
            'far fa-sync': true
        })

        const fetchDataExternal = this.fetchData.bind(this,tableSelfState)

        return (
            <div>
                <h1 className="marginB">API access log</h1>

                <div className="clearfix marginB half innerB">
                    <button className="btn btn-default  pull-left marginR "
                            disabled={loading}
                            onClick={fetchDataExternal}><i className={refreshCx}/></button>
                </div>

                <div className="content">

                    <div>
                        <ReactTable
                            manual
                            onFetchData={this.fetchData}
                            FilterComponent={CustomTableFilter}

                            PaginationComponent={CustomPaginationComponent}
                            showPaginationTop={true}
                            showPageSizeOptions={true}
                            pageSizeOptions={[10, 25, 50, 100, 200]}
                            className="audit-report"
                            paginationClassName="pagination-filters clearfix"
                            data={dataList}
                            columns={columns}
                            defaultPageSize={25}
                            minRows={1}
                            loading={!this.state.loaded || this.state.loading}
                            totalRowsCount={totalResults}
                            pages={pages}
                        />

                    </div>

                </div>

                {!changesModalData ? null :
                    <Modal show={true} onHide={() => this.setState({changesModalData: null})}>
                        <ModalHeader closeButton>
                            <ModalTitle>Changed data</ModalTitle>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <p>The following data has changed:</p>
                                <Table size="sm" striped className="size13" >
                                    <thead>
                                    <tr>
                                        <th>Field</th>
                                        <th>Old Value</th>
                                        <th>New Value</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {changesModalDataArr.map((data) => {


                                        // Prepare column
                                        const column = data && data.column;
                                        const columnNice = _.startCase(column)

                                        // Format the data, if needed
                                        let oldValue = '',
                                            newValue = '';

                                        if (data && data.old) {
                                            oldValue = data.old.toString();

                                            // Unix timestamp?
                                            if (oldValue.match(/^[0-9]{10}$/)) {
                                                oldValue = moment.unix(oldValue).format('HH:mm:ss MMM D, YYYY')
                                            }

                                            // File size?
                                            if ('file_size' === column) {
                                                // Format to size
                                                oldValue = formatFileSize(oldValue)
                                            }
                                        }

                                        if (data && data.new) {
                                            newValue = data.new.toString();

                                            // Unix timestamp?
                                            if (newValue.match(/^[0-9]{10}$/)) {
                                                newValue = moment.unix(newValue).format('HH:mm:ss MMM D, YYYY')
                                            }

                                            // File size?
                                            if ('file_size' === column) {
                                                // Format to size
                                                newValue = formatFileSize(newValue)
                                            }
                                        }

                                        return (
                                            <tr key={`cmda-${column}`}>
                                                <td>{columnNice}</td>
                                                <td>{oldValue}</td>
                                                <td>{newValue}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </ModalBody>
                    </Modal>
                }
            </div>
        )
    }
}

export default ApiLogPage;