
import React from 'react'
import {
  Redirect,
  Route,
  withRouter
} from 'react-router-dom'

import ListenerUserStore from './../stores/ListenerUser';
import {WEB_LISTENER_URL_BASE_PATH} from '../lib/constants';

const ListenerRestrictedAreaComponent = ({ component: Component, ...rest }) => {
  // If the user is not logged in, we redirect to the login page
  if (!ListenerUserStore.isLoggedIn()) {
    // Force logout
    ListenerUserStore.logoutUser();

    return (
      <Redirect to={`${WEB_LISTENER_URL_BASE_PATH}/login`} />
    );
  }
    return (<Route {...rest} render={props => (
        ListenerUserStore.isLoggedIn() ? (
                <Component {...rest} {...props}/>
            ) : null
    )}/>
)}

const ListenerRestrictedArea = withRouter(ListenerRestrictedAreaComponent);

export default ListenerRestrictedArea;
