import React from 'react';
import UserStore from './../stores/User';
import SettingsStore from './../stores/Settings';

import LoginPage from '../../pages/login/page';


class AppLogin extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            user: UserStore.getUser(),
            config: SettingsStore.getConfig(),
            logoutRedirect: false
        }
    }

    render() {
        let { children } = this.props;

        let style = {};
        style.paddingLeft = 0;

        return (
            <div id="page-wrapper" className="page-wrapper" style={style}>

                <div className="page-content" id="page-content-wrapper">
                    <LoginPage />
                </div>
            </div>
        );
    }
}

const NoMatch = ({ location }) => (
    <div>
        <h3>The page you are looking for can not be found: <code>{location.pathname}</code></h3>
    </div>
)


export default AppLogin;

