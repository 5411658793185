import React from "react";
import classnames from "classnames";
import _ from "lodash"

import Settings from './../../common/stores/Settings';
import ApiClient from "../../common/lib/ApiClient";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Table from "@material-ui/core/Table";
import moment from "moment-timezone";
import UserStore from "../../common/stores/User";


class TopBox extends React.Component {

    render() {
        const {
            icon,
            iconColor,
            label,
            value
        } = this.props;

        let lvalue = value;

        lvalue = value;
        if ('online' === lvalue) {
            lvalue = (<span className="">Online</span> );
        }
        if ('offline' === lvalue) {
            lvalue = (<span className="text-danger">Offline</span> );
        }

        return (
            <div className="card top-card">
                <div className="card-body">
                    <Row>
                        <Col xs={5}>
                            <div className={classnames('icon-big text-center', iconColor)}>
                                {icon}
                            </div>
                        </Col>
                        <Col>
                            <div className="text-right">
                                <p className="card-category">{label}</p>
                                <h4 className="card-title">{lvalue}</h4>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default class HomePage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            dataLoaded: false,
        }
    }

    componentDidMount() {
        document.title = "Dashboard | " + Settings.getConfig().site_name;

        ApiClient.get({
            endpoint: '/api/v1/dashboard',
            query: {

            }

        }).then(res => {
            this.setState({
                dataLoaded: true,
                data: res.body.data,
            })

        }, (err) => {
            ApiClient.onError(err)
        })
    }

    render() {

        const {
            data,
            dataLoaded,
        } = this.state;

        let {timezone} = UserStore.getUser();
        timezone = timezone ? timezone : 'UTC';

        // Limit remotes_online_list and listeners_online_list to max 15 users
        const listeners_online_list = [],
              remotes_online_list = [];

        return (
            <>
                <h1 className="marginB" style={{paddingLeft: '15px'}}>Dashboard</h1>
                {/*<div className="dashboard-contact">
                    <i className="fal fa-info-circle marginR half"/>
                    For assistance  or additional information contact SpectraCall technical support at (408) 431-5560
                </div>*/}


                <div className="content-dashboard">
                    {!dataLoaded ?
                    <div className="page-loading-container"><Spinner animation="border" /></div>
                    :
                    <>
                        <Container fluid={true}>
                            <Row>
                                <Col>
                                    <TopBox
                                        icon={<i className="fal fa-head-side-headphones text-warning"/> }
                                        label="Total listeners"
                                        value={data.listeners_total}
                                    />
                                </Col>
                                <Col>
                                    <TopBox
                                        icon={<i className="fal fa-mobile text-danger"/>}
                                        label="Total remotes"
                                        value={data.remotes_total}
                                    />
                                </Col>
                                <Col>
                                    <TopBox
                                        icon={<i className="fal fa-phone text-success"/>}
                                        label="Voice server"
                                        value={data.voice_status}
                                    />
                                </Col>
                                <Col>
                                    <TopBox
                                        icon={<i className="fal fa-comments text-secondary"/>}
                                        label="Chat server"
                                        value={data.xmpp_status}
                                    />
                                </Col>
                                <Col>
                                    <TopBox
                                        icon={<i className="fal fa-users-cog text-primary"/>}
                                        label="Admin bot"
                                        value={data.chatbot_status}
                                    />
                                </Col>
                            </Row>
                        </Container>

                        <Container fluid={true} className="marginT">
                            <Row>
                                <Col>
                                    <div className="card table-card">
                                        <div className="card-body">
                                            <h4 className="card-title border-bottom">Online listeners</h4>
                                            {!data.listeners_online_list || !data.listeners_online_list.length ?
                                                <p>There are no listeners online</p>
                                                :
                                                <>
                                                <Table>
                                                    <tbody>
                                                    {_.slice(data.listeners_online_list, 0, 15).map((row, i) => {
                                                        return (
                                                            <tr key={'lol' + i}>
                                                                <td style={{padding: '5px 0'}}>{row}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                    </tbody>
                                                </Table>
                                                {data.listeners_online_list.length <= 15 ? null :
                                                <p className="marginT innerT half size12">and {`${data.listeners_online_list.length - 15} more`}</p>
                                                }
                                                </>
                                            }


                                        </div>
                                    </div>
                                </Col>

                                <Col>
                                    <div className="card table-card">
                                        <div className="card-body">
                                            <h4 className="card-title border-bottom">Online remotes</h4>

                                            {!_.slice(data.remotes_online_list, 0, 15) || !data.remotes_online_list.length ?
                                            <p>There are no remotes online</p>
                                            :
                                            <>
                                            <Table>
                                                <tbody>
                                                {data.remotes_online_list.map((row, i) => {
                                                    return (
                                                        <tr key={'rol' + i}>
                                                            <td style={{padding: '5px 0'}}>{row}</td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </Table>

                                            {data.remotes_online_list.length <= 15 ? null :
                                                <p className="marginT innerT half size12">and {`${data.remotes_online_list.length - 15} more`}</p>
                                            }
                                            </>
                                            }
                                        </div>
                                    </div>
                                </Col>

                                {/*
                                <Col>
                                    <div className="card table-card">
                                        <div className="card-body">
                                            <h4 className="card-title border-bottom">Recent events</h4>
                                            <div>

                                            </div>
                                            <Table>
                                                <tbody style={{verticalAlign: 'top'}}>
                                                {data.recent_events.map((row, i) => {

                                                    var statTs = moment(row.ts).tz(timezone);
                                                    var statKey = row.stat_key;//.replace('_', ' ')

                                                    return (
                                                        <tr key={'re' + i} className="">
                                                            <td style={{padding: '5px 0'}}>
                                                                <span className={classnames(
                                                                    "fa-stack call-log-icon",
                                                                    statKey === "call_incoming" && "incoming-call",
                                                                    statKey === "call_outgoing" && "outgoing-call",
                                                                )}>
                                                                  <i className="fal fa-phone-alt fa-stack-1x"/>
                                                                  <i className="fal fa-arrow-left fa-stack-1x "/>
                                                                </span>
                                                                <span className="text-weight-500">{row.username}</span>
                                                            </td>
                                                            <td className="text-right" style={{padding: '5px 0'}}>
                                                                <span className="help-block size12">
                                                                    {statTs.format('HH:mm:ss MMM D, YYYY')}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </Col>
                                */}
                            </Row>
                        </Container>
                    </>
                    }
                </div>
            </>
        );
    }
}
