import React from 'react'
import classnames from 'classnames'
//
// import _ from './utils'

const defaultButton = (props) => (
    <button {...props} className='-btn'>{props.children}</button>
)



export default class ReactTablePagination extends React.Component {
    constructor(props) {
        super(props)

        this.applyPage = this.applyPage.bind(this)

        this.state = {
            page: props.page
        }
    }
    getInitialState () {
        return {
            page: this.props.page
        }
    }

    componentWillReceiveProps (nextProps) {
        this.setState({page: nextProps.page})
    }

    getSafePage (page) {
        return Math.min(Math.max(page, 0), this.props.pages - 1)
    }

    changePage (page) {
        page = this.getSafePage(page)
        this.setState({page})
        this.props.onPageChange(page)

        if (!this.props.isTop) {
            const paginationTop = document.getElementById("table-pagination-top")
            const offset = function (el) {
                var rect = el.getBoundingClientRect(),
                    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
            }

            window.scrollTo({
                top: paginationTop ? offset(paginationTop).top - 30 : 0,
                behavior: 'smooth',
            })
        }
    }

    applyPage (e) {
        e && e.preventDefault()
        const page = this.state.page
        this.changePage(page === '' ? this.props.page : page)
    }

    render () {
        const {
            totalRowsCount,
            // Computed
            pages,
            // Props
            isTop,
            page,
            showPageSizeOptions,
            pageSizeOptions,
            pageSize,
            showPageJump,
            canPrevious,
            canNext,
            onPageSizeChange,
            className,
            PreviousComponent = defaultButton,
            NextComponent = defaultButton
        } = this.props


        const classes = [className, 'clearfix'].join(' ')

        return (
            <div
                className={classes}
                style={this.props.paginationStyle}
                id={`table-pagination-${isTop?'top':'bottom'}`}
            >

                {!totalRowsCount ? null :
                <div className="rows-count marginR marginB half marginT" style={{lineHeight: '27px'}}>{totalRowsCount + (totalRowsCount === 1 ? " row" : " rows") + " found"}</div>
                }

                <div className="pull-left marginR margin-bottom-10 btn-group">
                    <button className="btn   btn-pagination"
                            disabled={!canPrevious}
                            onClick={(e) => {
                                if (!canPrevious) return
                                this.changePage(page - 1)
                            }}>
                        <i className="fa fa-chevron-left"/>
                    </button>

                    <button className="btn   btn-pagination"
                            onClick={(e) => {
                                if (!canNext) return
                                this.changePage(page + 1)
                            }}
                            disabled={!canNext}>
                        <i className="fa fa-chevron-right"/>
                    </button>
                </div>

                <div className="pull-left marginR margin-bottom-10 ">
                    <div className="page-selector-addon">
                        <span className="">
                            {this.props.pageText}
                        </span>
                    </div>

                    <div className="page-selector-input">

                          <form className='-pageJump'
                                onSubmit={this.applyPage}
                          >
                              <input
                                  className="page-selector"
                                  type={this.state.page === '' ? 'text' : 'text'}
                                  onChange={e => {
                                      const val = e.target.value
                                      const page = val - 1
                                      if (val === '') {
                                          return this.setState({page: val})
                                      }
                                      this.setState({page: this.getSafePage(page)})
                                  }}
                                  value={this.state.page === '' ? '' : this.state.page + 1}
                                  onBlur={this.applyPage}
                              />
                          </form>

                      </div>

                        <div className="page-selector-addon">
                                <span className="">
                                    {this.props.ofText} <span className='-totalPages'>{pages}</span>
                                </span>
                        </div>

                        {showPageSizeOptions && (
                            <div className="page-selector-input marginL">
                                <span className="display-inline-block innerR marginR half">Show</span>
                                <span className='select-wrap -pageSizeOptions'>
                                  <select
                                      onChange={(e) => onPageSizeChange(Number(e.target.value))}
                                      value={pageSize}
                                  >
                                    {pageSizeOptions.map((option, i) => {
                                        return (
                                            <option
                                                key={i}
                                                value={option}>
                                                {option} {this.props.rowsText}
                                            </option>
                                        )
                                    })}
                                  </select>
                                </span>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
