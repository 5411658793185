import React,{Component} from "react";

import ApiClient from './../../common/lib/ApiClient';
import RecordingsStore from './../../common/stores/Recordings';
import SettingsStore from './../../common/stores/Settings';

import _ from 'lodash';
import moment from 'moment-timezone'

import RecordingRow from "./recording-row";
import Select from "react-select";
import DateRangePicker from "./recordings-daterangepicker";
import GenericPagination from "../../common/lib/GenericPagination";
import VideoRow from './video-row';


class RecordingsList extends React.Component {

    constructor(props) {
        super(props)

        this.getData            = this.getData.bind(this)
        this.handleDelete       = this.handleDelete.bind(this)
        this.handleFilterChange = this.handleFilterChange.bind(this)

        this.state = {
            availableFilters: {
                username: [],
                participants: [],
                timestamp: [],
            },
            loaded: false,
            loading: false,
            filters: {},
            filterRemote: '',
            filterParticipant: '',
            filterTimerange: ( moment().subtract(1, 'years').startOf('day').unix() ).toString(),

            filterTimerangeStart: moment().subtract(1, 'years').startOf('day'),

            query: '',
            page: 0,
            pages: 1,
            pageSize: 5,
            totalRows: 0,
        };
    }

    async getData() {

        this.setState({
            loading: true,
        })

        const data = await RecordingsStore.loadFromServer({
            query: {
                include: 'subscription'
            }
        });

        // Prepare filters and filters indexes
        let filters = {
            username: [],
            participants: [],
            timestamp: [],
        }

        for (const i in data) {
            const {
                subscription,
                participants,
            } = data[i];

            data[i]._filters = {
                username: subscription && subscription.data && subscription.data.display_name,
                timestamp: data[i].recorded_at,
                participants: participants.users && _.flatMap(participants.users, (u) => u.display_name)
            }

            filters.username     = _.union(filters.username, [data[i]._filters.username]);
            filters.participants = _.union(filters.participants, data[i]._filters.participants);
            filters.timestamp    = _.union(filters.timestamp, [data[i]._filters.timestamp]);
        }

        this._dataList = data;
        this._filters = filters;

        this.setState({
            data,
            availableFilters: filters,
            loaded: true,
            loading: false,
        })

        this.applyFilters()


    }

    applyFilters() {
        const {
            filterRemote,
            filterParticipant,
            filterTimerange,
        }  = this.state;

        let data = this._dataList;

        // Remote/username filter
        if (filterRemote) {
            data = _.filter(data, (d) => -1 !== d._filters.username.indexOf(filterRemote))
        }

        // Participant filter
        if (filterParticipant) {
            data = _.filter(data, (d) => -1 !== d._filters.participants.indexOf(filterParticipant))
        }

        // Time range filter
        if (filterTimerange && -1 !== filterTimerange.indexOf(':')) {
            const range = filterTimerange.split(':');
            data = _.filter(data, (d) =>
                d._filters.timestamp >= range[0] && d._filters.timestamp <= range[1]
            );
        }

        // Apply pagination
        let {
            page,
            pageSize,
        } = this.state;

        let totalRows = data.length;

        // Should we re-adjust the current page?
        const totalPages = Math.ceil(totalRows/pageSize);
        if (page >= totalPages) {
            page = totalPages - 1;
        }
        if (page < 0) {
            page = 0;
        }
        data = _.slice(data, page * pageSize, (page+1) * pageSize);

        // Apply filtered data
        this.setState({
            data,
            page,
            totalRows,
        })
    }

    handleFilterChange(ev) {
        const {
            value,
            name,
        } = ev.target;

        this.setState({
            [name]: value

        },() => this.applyFilters());
    }

    handleFilterTimerangeChange = (startDate,endDate) => {

        let newts = ( startDate.unix() ).toString() + ':';
        if ( endDate ) {
            newts = newts + ( endDate.unix() ).toString();
        }

        this.setState({
            filterTimerange: newts,
        }, () => {
            this.applyFilters()
        })
    };

    handlePageChange = (newPage) => {
        this.setState({page: newPage}, () => this.applyFilters());
    }

    handlePageSizeChange = (newPageSize) => {
        const {
            pageSize,
            page,
        } = this.state;

        var currentRow = pageSize * page;
        var newPage = Math.floor(currentRow / newPageSize);

        this.setState({
            pageSize: newPageSize,
            page: newPage,
        });
    }

    handleDelete (id) {

        ApiClient.delete({
            endpoint: '/api/v1/storage/recordings/' + id

        }).then(function(res) {
            this.getData()


        }.bind(this), (err) => {
            ApiClient.onError(err)
        })
    }

    componentDidMount() {
        const { type } = this.props;

        document.title = "Recordings | " + SettingsStore.getConfig().site_name;
        if (type) {
            document.title = type.charAt(0).toUpperCase() + type.slice(1) + " | " + SettingsStore.getConfig().site_name;
        }

        this.getData();
    }

    render() {

        let {
            availableFilters,
            data,
            filterRemote,
            filterParticipant,
            filterTimerange,
            filterTimerangeStart,
            loading,
            page,
        } = this.state;

        const remotesFilter = (availableFilters.username && availableFilters.username.map((o) => {
            return {
                label: o,
                value: o,
            }
        })) || [];

        const participantsFilter = (availableFilters.participants && availableFilters.participants.map((o) => {
            return {
                label: o,
                value: o,
            }
        })) || [];

        return (
            <React.Fragment>

                {/*<h1>Recordings</h1>*/}
                <div className="pl-3 pb-2">
                    <h1 className="margin-top-none">Recordings</h1>
                </div>
                <div className="content-filters">
                    <div>
                        <DateRangePicker
                            onChange={this.handleFilterTimerangeChange}
                            maxSpan={{ days: 730 }}
                            startDate={filterTimerangeStart}
                        />
                    </div>
                    <div>
                        <Select
                            value={filterRemote ? {
                                label: filterRemote,
                                value: filterRemote
                            } : null}
                            options={remotesFilter}
                            blurInputOnSelect={true}
                            closeMenuOnSelect={true}
                            controlShouldRenderValue={true}
                            isClearable={true}
                            onChange={(value) => {
                                this.handleFilterChange({
                                    target: {
                                        value: value ? value.value : '',
                                        name: 'filterRemote'
                                    }
                                })
                            }}
                            pageSize={7}
                            placeholder="Filter by remote"
                        />
                    </div>
                    <div>
                        <Select
                            value={filterParticipant ? {
                                label: filterParticipant,
                                value: filterParticipant
                            } : null}
                            options={participantsFilter}
                            blurInputOnSelect={true}
                            closeMenuOnSelect={true}
                            controlShouldRenderValue={true}
                            isClearable={true}
                            onChange={(value) => {
                                this.handleFilterChange({
                                    target: {
                                        value: value ? value.value : '',
                                        name: 'filterParticipant'
                                    }
                                })
                            }}
                            pageSize={7}
                            placeholder="Filter by participants"
                        />
                    </div>

                </div>

                <div className="content-pagination">

                    <GenericPagination
                        className="generic-pagination"

                        page={page}
                        pageSize={this.state.pageSize}
                        pages={Math.ceil(this.state.totalRows/this.state.pageSize)}
                        totalRowsCount={this.state.totalRows}

                        showPageSizeOptions={true}
                        pageSizeOptions={[5, 10, 50, 100, 200]}
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        onRefreshData={this.getData}
                        loading={loading}
                    />
                </div>

                <div className="content-cards">

                    {data && data.map((el,i) => {

                        // Prepare recording regions
                        let regions = [];
                        if (el.regions && el.regions.data) {
                            regions = el.regions.data.map((d) => {
                                return {
                                    id: d.frontend_region_id,
                                    start: d.region_start,
                                    end: d.region_end,
                                }
                            })
                        }

                        if (el.file_type && el.file_type === 'video') {
                            return (
                              <VideoRow
                                key={`recording-row-${el.id}-${i}`}
                                idx={i}
                                id={el.id}
                                data={el}
                                regions={regions}
                                onDelete={this.handleDelete}
                              />
                            )
                        }

                        return (
                            <RecordingRow
                                key={`recording-row-${el.id}-${i}`}
                                idx={i}
                                id={el.id}
                                data={el}
                                regions={regions}
                                onDelete={this.handleDelete}
                            />
                        )
                    })}

                </div>


                <div className="content-pagination">
                    <GenericPagination
                        className="generic-pagination"

                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        pages={Math.ceil(this.state.totalRows/this.state.pageSize)}
                        totalRowsCount={this.state.totalRows}

                        showPageSizeOptions={true}
                        pageSizeOptions={[5, 10, 50, 100, 200]}
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        onRefreshData={this.getData}
                        loading={loading}
                    />
                </div>
            </React.Fragment>
        );
    }
}


export default RecordingsList;
