import ApiClient from './../lib/ApiClient';

var EventEmitter = require('events').EventEmitter;
var emitter = new EventEmitter();

var appConfig = {
    site_name: ''
};


export default {
    getConfig: function() {
        return appConfig;
    },

    subscribe: function(callback, event) {
        emitter.on(event ? event : 'update', callback);
    },

    unsubscribe: function(callback, event) {
        emitter.removeListener(event ? event : 'update', callback);
    },

    setConfig: function(data) {
        appConfig = data;
        emitter.emit('update');
    },

    pullConfig: function(callback) {

        ApiClient.get({
            endpoint: '/api/v1/system/settings/list'

        }).then(res => {
            appConfig = res.body.data;

            // Let listeners know the user has logged in
            emitter.emit('update');

            // Execute callback
            if ( callback && typeof callback === 'function' ) {
                callback(appConfig);
            }
        })
    }
};
