import React from 'react';
import SipClient from '../../../common/lib/sip-client';
import ApiClient from '../../../common/lib/ApiClient';
import {WEB_LISTENER_API_BASE_PATH} from '../../../common/lib/constants';
import ListenerUser from '../../../common/stores/ListenerUser';
import SettingsStore from '../../../common/stores/Settings';
import Stopwatch from "./stop-watch";

const STATES = {
  disconnected: 'Disconnected from the SIP server',
  connected: 'Connected to the SIP server',
  registered: 'Registered on the network, waiting for incoming call',
  callWaiting: 'Waiting for remote to connect',
  callIncoming: 'Incoming call',
  callAnswered: 'Call running',
};

export default class VideoStream extends React.Component {
  constructor(props) {
    super(props);

    this.videoRef = React.createRef();

    this.sipConnection = null;

    this.state = {
      waitingForIncomingCall: false,
      loadingComplete: false,
      currentStatus: STATES.connecting,
      activeCall: false,

      rotatedCamera: false,
      mutedMic: false,
      mutedAudio: false,
      hasVideo: false,
    };
  }

  isVideoCallType(callType) {
    return callType === 'video' || callType === 'video-hd';
  }

  componentDidMount() {
    const { listenerUsername, listenerHa1, callType, remoteUsername } = this.props;
    
    SipClient.setupConnection({
      serverHostname: SettingsStore.getConfig().xmpp_host,
      username: listenerUsername,
      ha1: listenerHa1,
      audio: true,
      video: this.isVideoCallType(callType),
      disableLogging: true,
      videoElement: this.videoRef.current,
      events: {
        onRegistered: () => {
          console.log('[DELEGATE] onRegistered');
          this.setState({
            loadingComplete: true,
            waitingForIncomingCall: true,
            currentStatus: STATES.registered,
            activeCall: false,
          });

          // Send stanza to the remote device to call us
          ApiClient.post({
            endpoint: `${WEB_LISTENER_API_BASE_PATH}/remote/request-incoming-call`,
            data: {
              listener: listenerUsername,
              remote: remoteUsername,
              callType,
            }
          }, ListenerUser.getJwt())
            .then((res) => {})
            .catch((err) => console.log(err));
        },
        onCallReceived: () => {
          console.log('[DELEGATE] onCallReceived');
          this.setState({ currentStatus: STATES.callIncoming, waitingForIncomingCall: false, activeCall: false, hasVideo: this.isVideoCallType(callType) });
        },
        onCallAnswered: () => {
          console.log('[DELEGATE] onCallAnswered');
          this.setState({ currentStatus: STATES.callAnswered, waitingForIncomingCall: false, activeCall: true, hasVideo: this.isVideoCallType(callType) });
        },
        onCallHangup: () => {
          console.log('[DELEGATE] onCallHangup');
          this.setState({ currentStatus: STATES.registered, waitingForIncomingCall: true, activeCall: false, hasVideo: this.isVideoCallType(callType) });
        },
        onServerConnect: () => {
          console.log('[DELEGATE] onServerConnect');
          this.setState({ currentStatus: STATES.connected, waitingForIncomingCall: false, activeCall: false, hasVideo: this.isVideoCallType(callType) });
        },
        onServerDisconnect: (error) => {
          this.setState({ currentStatus: STATES.disconnected, waitingForIncomingCall: false, activeCall: false, hasVideo: this.isVideoCallType(callType) });
          console.log('[DELEGATE] onServerDisconnect. Error: ', error);
        },
      },
    })
      .then((simpleUser) => {
        // console.log('Success');
        this.sipConnection = simpleUser;
        if (this.sipConnection) {
          this.sipConnection.mute();
        }
      })
      .catch((error) => console.error('Failure', error));
  }

  componentWillUnmount() {
    this.breakdownConnection();
  }

  breakdownConnection = () => {
    if (this.sipConnection && this.sipConnection.isConnected()) {
      this.sipConnection.disconnect();
    }
  };

  hangup = (event) => {
    const { onCallEnded } = this.props;
    event.preventDefault();

    onCallEnded();
  }

  sendSwitchCameraRequest = async(event) => {
    const { listenerUsername, remoteUsername } = this.props;
    const { rotatedCamera } = this.state;
    event.preventDefault();

    // Send stanza to the remote device to call us
    ApiClient.post({
      endpoint: `${WEB_LISTENER_API_BASE_PATH}/remote/request-camera-swap`,
      data: {
        listener: listenerUsername,
        remote: remoteUsername,
      }
    }, ListenerUser.getJwt())
      .then((res) => {})
      .catch((err) => console.log(err));

    this.setState({ rotatedCamera: !rotatedCamera });
  }

  render() {
    const { activeCall, loadingComplete, currentStatus, waitingForIncomingCall, mutedAudio, rotatedCamera, hasVideo } = this.state;
 
    const vidEl = hasVideo ?
     (<video ref={this.videoRef} className="video-element" autoPlay />) : 
     (<video ref={this.videoRef} className="video-element-hidden" autoPlay />);

    return (

      <div className="d-flex flex-column h-100 bg-dark">
      
        <div className="stream-heading px-4 py-4">
          <div className="mb-0 stream-status-message text-weight-bold text-muted">{currentStatus}</div>
          {activeCall && (
            <Stopwatch /> 
          )}
        </div>

        <div className="stream-container flex-grow-1">
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          
          {activeCall && !hasVideo && (
            <div className="call-icon d-flex justify-content-center align-items-center">
              <i className="fa fa-phone fa-5x text-muted" />
            </div>
          )}

          {vidEl}
          
          {!loadingComplete && (
            <div className="stream-client-status">
              <div className="loading-overlay text-center">
                Loading &nbsp;
                <i className="fal fa-spin fa-spinner fa-3x " />
              </div>
            </div>
          )}
          {waitingForIncomingCall && (
            <div className="stream-client-status">
              <div className="loading-overlay text-center">
                Waiting for incoming call &nbsp;
                <i className="fal fa-wifi fa-3x animate-blink" />
              </div>
            </div>
          )}
        </div>

        {/*<div className={`stream-video-controls ${activeCall && this.sipConnection ? '' : 'disabled'}`}>*/}
        <div className="stream-video-controls">
          <div className="d-flex flex-row justify-content-center align-items-center" style={{ gap: 16 }}>
            <button
              type="button"
              className="btn btn-light"
              onClick={this.sendSwitchCameraRequest}>
              <span className={`fa-stack fa-1x ${!rotatedCamera ? '' : 'fa-rotate-180'}`}>
                <i className="fas fa-video fa-stack-1x size30" />
                <i className="fa fa-arrows-alt-h fa-stack-1x fa-inverse size18 w-auto ml-2" />
              </span>
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={this.hangup}>
              <i className="fal fa-phone-slash fa-fw" />
            </button>
            <button
              type="button"
              className="btn btn-light"
              onClick={(event) => {
                event.preventDefault();
                if (mutedAudio) {
                  this.videoRef.current.volume = 0.6;
                } else {
                  this.videoRef.current.volume = 0;
                }

                this.setState({ mutedAudio: !mutedAudio });
              }}>
              <i className={`fal fa-fw ${!mutedAudio ? 'fa-volume-up' : 'fa-volume-slash'}`} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
