import jwt_decode from 'jwt-decode';
import {WEB_LISTENER_JWT_STORAGE_KEY} from '../lib/constants';

let EventEmitter = require('events').EventEmitter;
let emitter = new EventEmitter();

let userData = {
    username: '',
    isLoggedIn: false
};

let _jwt = null;
let _isLoggedIn = 0;

export default {
    getUser: function() {
        return userData;
    },

    subscribe: function(callback, event) {
        emitter.on(event ? event : 'update', callback);
    },

    unsubscribe: function(callback, event) {
        emitter.removeListener(event ? event : 'update', callback);
    },

    postLogin: function(data) {
        userData = data;
        emitter.emit('update');
    },

    loginUser: function(jwt, callback) {

        // Log the user in
        userData    = jwt_decode(jwt);
        _isLoggedIn = true;
        _jwt        = jwt;

        // We save the JWT in localStorage to keep the user authenticated. We’ll learn more about this later.
        localStorage.setItem(WEB_LISTENER_JWT_STORAGE_KEY, jwt);

        // Let listeners know the user has logged in
        emitter.emit('update');

        // Execute callback
        if ( callback && typeof callback === 'function' ) {
            callback(jwt);
        }
    },

    logoutUser: function() {

        userData    = {};
        _isLoggedIn = false;
        _jwt        = null;

        // We delete the JWT from local storage
        localStorage.removeItem(WEB_LISTENER_JWT_STORAGE_KEY);

        // Let listeners know the user has logged out
        emitter.emit('logout');
    },

    getJwt: function() {
        return localStorage[WEB_LISTENER_JWT_STORAGE_KEY];
    },

    isLoggedIn: function() {
        return _isLoggedIn;
    },
};
