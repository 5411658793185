
import React from "react";
import _ from "underscore";
import moment from "moment";

// Pond
import { TimeSeries, TimeRange } from "pondjs";

// Imports from the charts library
import {
    ChartContainer,
    ChartRow,
    Charts,
    YAxis,
    AreaChart,
    Resizable,
    Legend,
    MultiBrush
} from "react-timeseries-charts";

import styler from "../../../node_modules/react-timeseries-charts/lib/js/styler";

import ApiClient from "../../common/lib/ApiClient";
import Card from "react-bootstrap/Card";


const colors = require("./../../common/lib/colors.json");

const upDownStyle = styler([
    { key: "call_outgoing", color: colors.stats[3]/*, width: 2, fill: 'none'*/ },
    { key: "call_incoming", color: colors.stats[2]/*, width: 2, fill: 'none'*/ }
]);

const trackerStyle = {
    line: {
        stroke: "#000000",
        cursor: "crosshair",
        pointerEvents: "none"
    }
};

class Calls extends React.Component {

    _isMounted = false

    constructor(props) {
        super(props);
        this.handleRescale = _.debounce(this.rescale, 300);

        this.fetchData = this.fetchData.bind(this)

        this.state = {
            loading: false,
            loaded: false,
            forceReload: false,
            tracker: null,
            timerange: null,
            selected: 1,
            selections: []
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchData()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        if(this.props.timerangeFilter !== prevProps.timerangeFilter)
        {
            if (this._isMounted) {
                this.fetchData();
            }
        } else {

            if(JSON.stringify(this.props.filters) !== JSON.stringify( prevProps.filters ))
            {
                if (this._isMounted) {
                    this.fetchData();
                }
            }
        }
    }

    fetchData() {
        const {timerangeFilter,filters} = this.props;

        this.setState({loading: true});

        ApiClient.get({
            endpoint: '/api/v1/analytics/statistics/calls',
            query: {
                filters: filters,
                timerange: timerangeFilter
            }

        }).then(res => {
            // Data
            const messagesData = res.body.data;

            const call_incoming = [];
            const call_outgoing = [];

            _.each(messagesData, val => {
                const timestamp = moment(new Date(`${val["time"]}`));
                const numConnection = val["call_outgoing"];
                const messagesReceived = val["call_incoming"];
                call_incoming.push([timestamp.toDate().getTime(), messagesReceived]);
                call_outgoing.push([timestamp.toDate().getTime(), numConnection]);
            });

            const call_outgoingSeries = new TimeSeries({
                name: "call_outgoing",
                columns: ["time", "call_outgoing"],
                points: call_outgoing
            });

            const call_incomingSeries = new TimeSeries({
                name: "call_incoming",
                columns: ["time", "call_incoming"],
                points: call_incoming
            });

            const callSeries = TimeSeries.timeSeriesListMerge({
                name: "messages",
                seriesList: [call_outgoingSeries, call_incomingSeries]
            });

            this.setState({
                data: res.body.data,
                loaded: true,
                loading: false,
                timerange: callSeries.range(),

                call_outgoingSeries,
                call_incomingSeries,
                callSeries
            })

        }, (err) => {
            ApiClient.onError(err)
        })

    }

    rescale(timerange, active = this.state.active) {
        const {call_outgoingSeries, call_incomingSeries, callSeries} = this.state;
        let max = 100;
        const maxCall_incoming = call_incomingSeries.crop(this.state.timerange).max("call_incoming");
        if (maxCall_incoming > max) max = maxCall_incoming;
        const maxCall_outgoing = call_outgoingSeries.crop(this.state.timerange).max("call_outgoing");
        if (maxCall_outgoing > max) max = maxCall_outgoing;
        this.setState({ max });
    }

    handleTrackerChanged = (t, scale) => {
        const {call_outgoingSeries, call_incomingSeries, callSeries} = this.state;
        this.setState({
            tracker: t,
            trackerEventOutgoing: t && call_outgoingSeries.at(call_outgoingSeries.bisect(t)),
            trackerEventIncoming: t && call_incomingSeries.at(call_incomingSeries.bisect(t)),
            trackerX: t && scale(t)
        });
    };

    handleTimeRangeChange = timerange => {
        this.setState({ timerange });
        //this.handleRescale(timerange);
    };

    handleSelectionChange = (timerange, i) => {
        const selections = this.state.selections;
        selections[i] = timerange;
        this.setState({ selections });
    };

    render() {
        const {loading, loaded} = this.state;
        if ( !loaded || loading ) {
            return (
                <div>
                    <Card className="marginB panel-stats">
                        <Card.Body>
                        <Card.Title>Calls</Card.Title>
                        <div>
                            <div className="innerAll half text-center marginAll">Loading
                                &nbsp;
                                <i className={'fa fa-spin fa-spinner fa-1x '}/>
                            </div>
                        </div>
                        </Card.Body>
                    </Card>
                </div>
            );
        }

        const dateStyle = {
            fontSize: 12,
            color: "#AAA",
            borderWidth: 1,
            borderColor: "#F4F4F4"
        };

        const markerStyle = {
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            color: "#AAA",
            marginLeft: "5px"
        };

        const {call_outgoingSeries, call_incomingSeries, callSeries} = this.state;
        const max = _.max([call_outgoingSeries.max("call_outgoing"), call_incomingSeries.max("call_incoming")]);
        const axistype = "linear";
        const tracker = this.state.tracker ? `${this.state.tracker}` : "";

        return (
            <div>
                <Card className="marginB panel-stats">
                    <Card.Body>
                    <Card.Title>Calls</Card.Title>
                    <div>

                        <div className="row">
                            <div className="col-md-4">
                                <Legend
                                    type="swatch"
                                    style={upDownStyle}
                                    categories={[
                                        { key: "call_outgoing", label: "Outgoing" },
                                        { key: "call_incoming", label: "Incoming" }
                                    ]}
                                />
                            </div>
                            <div className="col-md-8">
                                <span style={dateStyle}>{tracker}</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                {this.state.tracker ? (
                                    <div style={{ position: "relative" }}>
                                        <div style={{ position: "absolute", left: this.state.trackerX }}>
                                            <div style={markerStyle}>
                                                Outgoing: {this.state.trackerEventOutgoing.get("call_outgoing")}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                position: "absolute",
                                                left: this.state.trackerX,
                                                top: "220px"
                                            }}
                                        >
                                            <div style={markerStyle}>
                                                Incoming: {this.state.trackerEventIncoming.get("call_incoming")}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                <Resizable>
                                    <ChartContainer
                                        padding={20}
                                        paddingTop={5}
                                        utc={true}
                                        timeRange={this.state.timerange}
                                        trackerPosition={this.state.tracker}
                                        trackerStyle={trackerStyle}
                                        onTrackerChanged={this.handleTrackerChanged}
                                        enablePanZoom={false}
                                        /*enableDragZoom*/
                                        maxTime={callSeries.range().end()}
                                        minTime={callSeries.range().begin()}
                                        minDuration={1000 * 3600}
                                        onBackgroundClick={() => this.setState({ selection: null })}
                                        onTimeRangeChanged={this.handleTimeRangeChange}
                                    >
                                        <ChartRow height="250" debug={false}>
                                            <YAxis
                                                id="messageaxis"
                                                label="Number of calls"
                                                labelOffset={0}
                                                min={-max}
                                                max={max}
                                                absolute={true}
                                                width="60"
                                                type={axistype}
                                            />
                                            <Charts>
                                                <AreaChart
                                                    axis="messageaxis"
                                                    series={callSeries}
                                                    interpolation={'curveLinear'}
                                                    columns={{
                                                        up: ["call_outgoing"],
                                                        down: ["call_incoming"]
                                                    }}
                                                    style={upDownStyle}
                                                />
                                                <MultiBrush
                                                    timeRanges={this.state.selections}
                                                    style={i => {
                                                        if (i === this.state.selected) {
                                                            return { fill: "#46abff" };
                                                        } else {
                                                            return { fill: "#cccccc" };
                                                        }
                                                    }}
                                                    allowSelectionClear
                                                    onTimeRangeChanged={this.handleSelectionChange}
                                                    onTimeRangeClicked={i => this.setState({ selected: i })}
                                                />
                                            </Charts>
                                        </ChartRow>
                                    </ChartContainer>
                                </Resizable>
                            </div>
                            { !this.state.selections || !this.state.selections.length ? null :
                            <div className="col-md-12">
                                <hr />
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">Date range</th>
                                        <th scope="col">Outgoing Max</th>
                                        <th scope="col">Outgoing Avg</th>
                                        <th scope="col">Incoming Max</th>
                                        <th scope="col">Incoming Avg</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.selections.map((tr, i) => {
                                        if ( typeof callSeries.crop(tr).max("call_outgoing") === 'undefined'
                                            || typeof callSeries.crop(tr).max("call_incoming") === 'undefined' ) {
                                            return null;
                                        }
                                        return (
                                            <tr
                                                key={i}
                                                style={
                                                    i === this.state.selected
                                                        ? { background: "#46abff60" }
                                                        : {}
                                                }
                                            >
                                                <td
                                                    onClick={() => this.setState({ selected: i })}
                                                >{`${tr.humanize()}`}</td>
                                                <td style={{ padding: 10 }}>{`${callSeries.crop(tr).max("call_outgoing")}`}</td>
                                                <td style={{ padding: 10 }}>{parseInt(`${callSeries.crop(tr).avg("call_outgoing")}`)}</td>
                                                <td style={{ padding: 10 }}>{`${callSeries.crop(tr).max("call_incoming")}`}</td>
                                                <td style={{ padding: 10 }}>{parseInt(`${callSeries.crop(tr).avg("call_incoming")}`)}</td>
                                                <td>
                                                    <i
                                                        className="fa fa-times"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            const selection = this.state.selections;
                                                            this.setState({
                                                                selections: selection.filter(
                                                                    (item, j) => j !== i
                                                                ),
                                                                selected: null
                                                            });
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                            }
                        </div>
                    </div>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

// Export example
export default Calls;