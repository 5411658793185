/**
 * App entry point
 */

// Polyfill
import 'babel-polyfill';

// Libraries
import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router
} from 'react-router-dom'
import 'bootstrap';

// Base styling
import './scss/style.scss';

// Stores
import SettingsStore from './common/stores/Settings';
import UserStore from './common/stores/User';
import ListenerUserStore from './common/stores/ListenerUser';

// Render the router
import App from './common/components/App';
import AppLogin from './common/components/AppLogin';
import {
    APP_JWT_STORAGE_KEY,
    DOM_APP_EL_ID,
    WEB_LISTENER_JWT_STORAGE_KEY,
    WEB_LISTENER_URL_BASE_PATH
} from './common/lib/constants';
import {startsWith} from 'lodash';
import ListenerApp from './common/components/AppListener';

window.magicKeyword = '';
window.magicMenu    = false;

// Load application config and render UI only after it was loaded
SettingsStore.pullConfig(() => {
    // If the current URL starts with the web listener section root path, we render the web listener app
    if (startsWith(window.location.pathname, WEB_LISTENER_URL_BASE_PATH)) {
        const listenerJwt = localStorage.getItem(WEB_LISTENER_JWT_STORAGE_KEY);
        if (listenerJwt) {
            ListenerUserStore.loginUser(listenerJwt);
        }

        renderListenerApp();
    }
    // Normal app
    else {
        const jwt = localStorage.getItem(APP_JWT_STORAGE_KEY);

        if (jwt) {
            UserStore.loginUser(jwt);
        }
        renderApp();
    }
})

function renderApp() {
    if (!UserStore.isLoggedIn()) {
        ReactDOM.render((
            <Router>
                <AppLogin />
            </Router>
        ), document.getElementById(DOM_APP_EL_ID));

    } else {
        ReactDOM.render((
            <Router>
                <App />
            </Router>
        ), document.getElementById(DOM_APP_EL_ID));
    }
}

function renderListenerApp() {
    // If the current URL starts with the web listener section root path, we render the web listener app
    ReactDOM.render((
      <Router>
          <ListenerApp />
      </Router>
    ), document.getElementById(DOM_APP_EL_ID));
}
